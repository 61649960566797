import React, { useEffect, useState } from 'react';
import './Login.css'
import logo from '../../Images/Logo/Logo';
import carousel1 from '../../Images/Carousel/Carousel1';
import carousel2 from '../../Images/Carousel/Carousel2';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { SignInApi } from '../../Config/api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { storeCompanyDetails, storeUserDetails, storeUserToken } from '../../Services/Storage';
import {  useNavigate } from 'react-router-dom';
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); const [formData, setFormData] = useState({
    email: '',
    password: ''
  }); const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const handleSubmit = async () => {
    let newErrors = {};
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    } if (formData.password.trim() === '') {
      newErrors.password = 'Password is required';
    }
    if (formData.password.trim() === '') {
      newErrors.password = 'Password is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await SignInApi(formData);
        if (response) {
          storeUserToken(response.user_details.fcm_token);
          storeUserDetails(response.user_details);
          console.log("reshdsfhgjdfjkgj " , response.user_details);
          storeCompanyDetails(response.company_details);
          toast.success('Login successful!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          });
          setTimeout(() => {
            navigate('/dashboard');
          }, 100); // Navigate after 2 seconds (2000 ms)
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          console.log(errorData, "erfiuedofjueoirhjefrhjekhk")
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key]; // Assign the specific error message to the respective field
            }
          }
    
          setErrors(newErrors);
        } else {
          toast.error('Failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
    
          });
    
          // Handle generic errors
          setErrors({
            response_error: 'An unexpected error occurred. Please try again later.',
          });
        }
      }
      finally {
        setLoading(false);
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  const [cpserror, setCapsError] = useState("");
  useEffect(() => {
    const handleCapsLock = (e) => {
      const isCapsLockOn = e.getModifierState
        ? e.getModifierState("CapsLock")
        : false;
      if (isCapsLockOn) {
        setCapsError("Caps Lock is on");
      } else {
        setCapsError("");
      }
    }; document.addEventListener("keydown", handleCapsLock); return () => {
      document.removeEventListener("keydown", handleCapsLock);
    };
  }, []);
  // if (isAuthenticated()) {
  //   return <Navigate to="/dashboard" />
  // } 
  return (
    <div className='login-container'>
      <div className='login-container-sub'>
        <div className='login-form'>
          <div className='login-logo'>
            <img className='login-logo-sub' src={logo.logo_img} alt='logo' />
          </div>
          <h2 className='heading-login'>SIGN IN</h2>
          <div className='login-content'>
            <label> Email</label>
            <input type='text' onKeyDown={handleKeyPress} name='email' onChange={handleInputChange} />
            {errors.email && (
              <div className="error-message-view">{errors.email}</div>
            )}
            <label>Password</label>
            <input type={showPassword ? "text" : "password"} onKeyDown={handleKeyPress} name='password' onChange={handleInputChange} />
            <FontAwesomeIcon className="login-password-icon" icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />{errors.password && (
              <div className="error-message-view">{errors.password}</div>
            )}
            <div className='login-forgot-password'>
              <a href="/forgot-password">Forgot Password?</a></div>
            {errors.response_error && (
              <div className="error-message-view">{errors.response_error}</div>
            )}
            <div className="error">{cpserror}</div>
            <button className='login-btn' onClick={handleSubmit} disabled={loading}> {loading ? <span className='spinner'></span> : 'Submit'}
            </button>
            <div className='login-signup'>
              <p>Don't have a Staffin account?
                <a href="/signup"> Sign up</a>
              </p>
            </div>
          </div>
        </div>
        <div className='login-carousel'>
          <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} autoPlay={true} showArrows={false}
            interval={3000}>
            <div>
              <img src={carousel1.carousel_1} alt='carousel-img-1' /></div>
            <div>
              <img src={carousel2.carousel_2} alt='carousel-img-2' />
            </div></Carousel>
        </div>
      </div>
      </div>
  )
}
export default Login