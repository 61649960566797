import React, { useState, useRef } from 'react';
import "../../Style/Employeeform.css";
import Sidebar from '../../../Sidebar/Sidebar';
import Header from '../../../Header/Header';
import '../../Style/Addemp.css'
import {  useLocation, useNavigate } from 'react-router-dom'
import { convertTo12HourFormat } from '../../../Component/TimeFormat';
import { EmployeeBasicApi } from '../../../../Config/api';
import { useSelector } from 'react-redux';
import { getCompanyDetails, getUserDetails } from '../../../../Services/Storage';
import userdemoimg from '../../../../Images/Pagesimg/userdemo';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';


const Basicdetails = () => {
  const location = useLocation();
  const { name, user_id } = location.state || {};
  const navigate = useNavigate();
  const companies = useSelector((state) => state.companies);
  const designationRedux = useSelector((state) => state.designations);
  const departmentRedux = useSelector((state) => state.department);
  const departmentHeadRedux = useSelector((state) => state.departmentHead);
  console.log(departmentHeadRedux,"djksdj")
  const departmentTlRedux = useSelector((state) => state.departmentTl);
  const [fromvalue, Setfromtime] = useState(null);
  const [tovalue, Settotime] = useState(null);
  const [errors, setErrors] = useState({});
  const user_details = getUserDetails();
  const company_details = getCompanyDetails();
  const [selectedimg, setselectedimg] = useState(null);
  const LeaveComponentRedux = useSelector((state) => state.leavecomponents);
  const fileinput = useRef(null);
  const [loading, setloading] = useState(false);
  const handleuploadimg = () => {
    fileinput.current.click();
  }
  const [basicInfo, setBasicInfo] = useState({
    emp_name: name,
    emp_id: " ",
    work_email: " ",
    father_name: " ",
    dob: "",
    gender: " ",
    blood_group: " ",
    emergency_contact: " ",
    doj: " ",
    permission_hr: " ",
    work_from: " ",
    work_to: " ",
    work_location: " ",
    designation: " ",
    department: " ",
    depart_head: " ",
    depart_tl: " ",
    register: user_id,
    user: user_details.id,
    staff_pic: "",
    company: company_details.company_id,
    eligible_leave: [],
  });

  const handleMultiSelectChange = (selected) => {
    const values = Array.from(selected, (option) => option.value);
    console.log("valueeesss", values);
    setBasicInfo((state) => ({
      ...state,
      eligible_leave: values,
    }));
  };
  const handlefromtimechange = (time) => {
    Setfromtime(time);
    setBasicInfo((state) => ({
      ...state,
      work_from: time,

    }));

  }
  const handletotimechange = (time) => {
    Settotime(time);
    setBasicInfo((state) => ({
      ...state,
      work_to: time,
    })

    )
  }
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'time') {
      console.log("select time ", value);
      setBasicInfo((state) => ({
        ...state, [name]: convertTo12HourFormat(value),
      }));
    }
    else if (event.target.type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        setBasicInfo((state) => ({
          ...state,
          [name]: file,
        }));
        setselectedimg(URL.createObjectURL(file)); // Set the selected image for preview
      } else {
        setselectedimg("");
        alert('Please upload a valid image file (jpg, png, etc.).');
      }
    }
    // else if (event.target.type === 'select-multiple') {
    //   const values = Array.from(event.target.selectedOptions, (option) => option.value);
    // console.log("Selected multiple values:", values);

    // setBasicInfo(prevState => ({
    //   ...prevState,
    //   eligible_leave: JSON.stringify(values),

    // }));
    // }
    else {
      setBasicInfo((state) => ({
        ...state, [name]: value,
      }));
    }
  }
  const handleSubmit = async () => {
    let newErrors = {};
    // if(basicInfo.emp_name.trim()===""){
    //   newErrors.emp_name = "Name is Required";
    // }else if(!/^[a-zA-Z\s]*$/.test(basicInfo.emp_name)){
    //   newErrors.emp_name = "Name must contain only alphabetics"
    // }
    if (basicInfo.emp_id.trim() === "") {
      newErrors.emp_id = "Employee Id is Required";
    }
    if (basicInfo.work_email.trim() === "") {
      newErrors.work_email = "Employee Email is Required";
    }
    if (basicInfo.father_name.trim() === "") {
      newErrors.father_name = "Father name is Required";
    }
    if (basicInfo.dob.trim() === "") {
      newErrors.dob = "Date of Birth is Required";
    }
    if (basicInfo.gender.trim() === "") {
      newErrors.gender = "Please Select Gender";

    }
    if (basicInfo.blood_group.trim() === "") {
      newErrors.blood_group = "Blood Group is Required";
    }
    if (basicInfo.emergency_contact.trim() === "") {
      newErrors.emergency_contact = "Emergency Contact No is Required";
    }
    if (basicInfo.doj.trim() === "") {
      newErrors.doj = "Date of Joining is Required";
    }
    if (basicInfo.work_from.trim() === "") {
      newErrors.work_from = "Work From is Required";
    }
    if (basicInfo.work_to.trim() === "") {
      newErrors.work_to = "Work To is Required";

    }
    if (basicInfo.work_location.trim() === "") {
      newErrors.work_location = "Select Work Location";
    }
    if (basicInfo.designation.trim() === "") {
      newErrors.designation = "Select Designation";
    }
    if (basicInfo.department.trim() === "") {
      newErrors.department = "Select Department";
    }
    // if (basicInfo.depart_head.trim() === "") {
    //   newErrors.depart_head = "Select Department Head";
    // }
    // if (basicInfo.depart_tl.trim() === "") {
    //   newErrors.depart_tl = "Select Department TL";
    // }
    if (basicInfo.permission_hr.trim() === "") {
      newErrors.permission_hr = "Permission Hour is Required";
    }
    if (basicInfo.eligible_leave.length === 0) {
      newErrors.eligible_leave = "Select Eligible Leave";
    }
    // if (!basicInfo.staff_pic) {
    //   newErrors.staff_pic = "Profile Image is Required"
    // }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true);
        //console.log("employee basic info json stringfy" , JSON.stringify(basicInfo))
        console.log("employee basic info json stringfy", basicInfo);
        const data = {
          ...basicInfo,
          eligible_leave: JSON.stringify(basicInfo.eligible_leave), // Convert only when sending
        };
        const response = await EmployeeBasicApi(data);
        if (response){
          toast.success('Basic Details added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
        
          navigate('/salarydetails', { state: { name: name, user_id: user_id } });
        }
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};

          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              // Add each error message to the newErrors object with the field name as the key
              newErrors[key] = errorData[key];
            }
          }

          console.log("new error", newErrors);

          // Set the errors using setErrors
          setErrors(newErrors);

          // setBasicInfo((state) => ({
          //   ...state,
          // }));
        } else {
          toast.error('Failed.Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],

          };
          console.log("error response ", error.response.data);
          for (const key in error.response.data) {
            if (error.response.data.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data[key]}`);

            }
          }
          console.log("new error ", newErrors);
          setErrors(newErrors);
          setBasicInfo((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setloading(false)
      }
    }
  }
  const [time, setTime] = useState(null);

  const handleChange = (newTime) => {
    setTime(newTime);
    setBasicInfo((state) => ({
      ...state,
      permission_hr: newTime,
    })
    )
    console.log(newTime)
  };
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='addemp-subheader'>
                <div className='addemp-subheader-sub'>
                  <div className='addemp-subheader1'>
                    <p>Add Basic Details</p>
                  </div>
                  <div className='addemp-subheader2'>
                    <div className='addemp-stepper'>
                      <div className='addemp-step-indicator'>
                        <div className='addemp-step'>
                          Personal Info
                        </div>
                        <div className='addemp-step addemp-selected' >Basic Details</div>
                        <div className='addemp-step'>Salary Details</div>
                        <div className='addemp-step'>Payment</div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className='addemp-line'></div>
              <div className='addemp-maincontent-sub'>
                <div className='addemp-form form-padding'>
                  <div className='addemp-form-sub'>
                    <div className='addemp-form-sub-main'>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-imgemp'>

                          <div className='emppersonalinfo-input-imgemp-sub'>
                            <div className='emppersonalinfo-input-imgemp-main' onClick={handleuploadimg}>


                              {selectedimg ?
                                <img src={selectedimg} alt='not_ft'/>
                                :
                                <img src={userdemoimg.demo_img} alt='not_ft'/>
                              }
                              <input type='file' style={{ display: 'none' }}
                                name='staff_pic' ref={fileinput} onChange={handleInputChange} />

                            </div>

                          </div>
                          <label className='label-fields'>Upload Image <span className='emppersonalinfo-mandatory'>*</span></label>
                          {/* {errors.staff_pic && (<div className='error-message-view'>{errors.staff_pic}</div>)} */}

                        </div>

                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Employee Name <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field' readOnly name='emp_name' onChange={handleInputChange} placeholder='Enter employee name' value={name} type='text' />
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Employee ID  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' name='emp_id' onChange={handleInputChange} value={basicInfo.emp_id} placeholder='Enter employee id' type='text' />
                          {errors.emp_id && (<div className='error-message-view'>{errors.emp_id}</div>)}
                        </div>

                      </div>
                      <div className='basicdetails-input-col'>
                        <div className='basicdetails-col-view'>
                          <label className='label-fields'>Work Email <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-inputfield-col' placeholder='abc@xyz.com' type='text' value={basicInfo.work_email} name="work_email" onChange={handleInputChange} />
                        </div>
                        {errors.work_email && (<div className='error-message-view'>{errors.work_email}</div>)}
                      </div>

                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Father Name <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input name='father_name' value={basicInfo.father_name} onChange={handleInputChange} className='emppersonalinfo-input-field' type='text' />
                          {errors.father_name && (<div className='error-message-view'>{errors.father_name}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Date Of Birth  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input name='dob' value={basicInfo.dob} onChange={handleInputChange} className='emppersonalinfo-input-field' type='date' />
                          {errors.dob && (<div className='error-message-view'>{errors.dob}</div>)}
                        </div>

                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Gender <span className='emppersonalinfo-mandatory'>*</span></label>
                          <select name="gender" onChange={handleInputChange} value={basicInfo.gender} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Gender</option>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                            <option value='other'>Other</option>
                          </select>
                          {errors.gender && (<div className='error-message-view'>{errors.gender}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Blood Group  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input name='blood_group' onChange={handleInputChange} value={basicInfo.blood_group}
                            className='emppersonalinfo-input-field' type='text' />
                          {errors.blood_group && (<div className='error-message-view'>{errors.blood_group}</div>)}
                        </div>

                      </div>

                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Emergency Contact No <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input name='emergency_contact' onChange={handleInputChange} value={basicInfo.emergency_contact} className='emppersonalinfo-input-field' type='text' />
                          {errors.emergency_contact && (<div className='error-message-view'>{errors.emergency_contact}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Date Of Joining  <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input name='doj' value={basicInfo.doj} onChange={handleInputChange} className='emppersonalinfo-input-field' type='date' />
                          {errors.doj && (<div className='error-message-view'>{errors.doj}</div>)}
                        </div>
                      </div>



                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Permission Hour <span className='emppersonalinfo-mandatory'>*</span></label>
                          {/* <input name='permission_hr' onChange={handleInputChange} value={basicInfo.permission_hr} className='emppersonalinfo-input-field' type='time' /> */}
                          <TimePicker
                            onChange={handleChange}
                            value={time}
                            className="time-picker"

                            format="HH:mm" // Internal format is 24-hour
                            clockIcon={null} // Optional: Hide the default clock icon
                          />
                          {errors.permission_hr && (<div className='error-message-view'>{errors.permission_hr}</div>)}
                        </div>
                        {/* <div className='emppersonalinfo-input-group'>
<label>Leave Eligible<span className='emppersonalinfo-mandatory'>*</span></label>

<select className='emppersonalinfo-input-field'  multiple name='eligible_leave' onChange={handleInputChange} >
  {
    LeaveComponentRedux.map((leavecomponent)=>(
      <option value={leavecomponent.id}> {leavecomponent.leave_type} </option>
    ))
  }
  
</select>
</div> */}
                        <div className='emppersonalinfo-input-group'>
                          <label>Leave Eligible <span className='emppersonalinfo-mandatory'>*</span></label>
                          <MultiSelect
                            options={LeaveComponentRedux.map(leavecomponent => ({
                              label: leavecomponent.leave_type,
                              value: leavecomponent.id
                            }))}
                            value={basicInfo.eligible_leave.map(id => ({
                              label: LeaveComponentRedux.find(leave => leave.id === id)?.leave_type,
                              value: id
                            }))}
                            onChange={handleMultiSelectChange}
                            labelledBy="Select Leave"
                          />
                          {errors.eligible_leave && (<div className='error-message-view'>{errors.eligible_leave}</div>)}
                        </div>
                      </div>


                      <p className='emp-work-p'>Work Timing:</p>
                      <div className='emppersonalinfo-input-row'>

                        <div className='emppersonalinfo-input-group'>
                          <label>From Time <span className='emppersonalinfo-mandatory'>*</span></label>
                          {/* <input name='work_from' onChange={handleInputChange} value={basicInfo.work_from} className='emppersonalinfo-input-field' type='time' /> */}
                          <TimePicker
                            onChange={handlefromtimechange}
                            value={fromvalue}
                            className="time-picker"

                          />
                          {errors.work_from && (<div className='error-message-view'>{errors.work_from}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>To Time <span className='emppersonalinfo-mandatory'>*</span></label>
                          {/* <input name='work_to' onChange={handleInputChange} value={basicInfo.work_to} className='emppersonalinfo-input-field' type='time' /> */}
                          <TimePicker
                            onChange={handletotimechange}
                            value={tovalue}
                            className="time-picker"

                          />
                          {errors.work_to && (<div className='error-message-view'>{errors.work_to}</div>)}
                        </div>
                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Work Location <span className='emppersonalinfo-mandatory'>*</span></label>
                          <select name='work_location' value={basicInfo.work_location} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Location</option>
                            {companies.map((company, index) => (
                              <option key={index} value={company.id}>
                                {company.company_name}
                              </option>
                            ))}
                          </select>
                          {errors.work_location && (<div className='error-message-view'>{errors.work_location}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Designation
                            <span className='emppersonalinfo-mandatory'>*</span></label>

                          <select name='designation' value={basicInfo.designation} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Designation</option>
                            {designationRedux.map((designation, index) => (
                              <option key={index} value={designation.id}>
                                {designation.designation}
                              </option>
                            ))}
                          </select>
                          {errors.designation && (<div className='error-message-view'>{errors.designation}</div>)}
                        </div>

                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Department<span className='emppersonalinfo-mandatory'>*</span></label>

                          <select name='department' value={basicInfo.department} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Department</option>
                            {departmentRedux.map((department, index) => (
                              <option key={index} value={department.id}>
                                {department.depart_name}
                              </option>
                            ))}
                          </select>
                          {errors.department && (<div className='error-message-view'>{errors.department}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Department Head <span className='emppersonalinfo-mandatory'>*</span></label>
                          <select name='depart_head' value={basicInfo.depart_head} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Department Head</option>
                            {departmentHeadRedux.map((department_head, index) => (
                              <option key={index} value={department_head.row_id}>
                                {department_head.depart_head_name}
                              </option>
                            ))}
                          </select>
                          {errors.depart_head && (<div className='error-message-view'>{errors.depart_head}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label>Department TL
                            <span className='emppersonalinfo-mandatory'>*</span></label>

                          <select name='depart_tl' value={basicInfo.depart_tl} onChange={handleInputChange} className='emppersonalinfo-input-field3'>
                            <option value=''>Select Department TL</option>
                            {departmentTlRedux.map((department_tl, index) => (
                              <option key={index} value={department_tl.row_id}>
                                {department_tl.depart_tl_name}
                              </option>
                            ))}
                          </select>
                          {errors.depart_tl && (<div className='error-message-view'>{errors.depart_tl}</div>)}

                        </div>
                      </div>
                      {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}
                      <div className='emppersonalinfo-submitbutton'>


                        <button onClick={handleSubmit} disabled={loading}>{loading ? <span className='spinner'></span> : 'Submit'}</button>
                      </div>
                      <div></div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Basicdetails