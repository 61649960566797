import React, { useState, useEffect } from 'react'
import { FaCircleUser } from 'react-icons/fa6';
import Sidebar from '../../../Sidebar/Sidebar';
import Header from '../../../Header/Header';
import '../../AddEmployees/Payment/Payment.css'
import '../../Style/Addemp.css'
import { UpdateEmployeePaymentApi } from '../../../../Config/api';
import { Store } from '../../../../Store';
import { setUpdatePaymentInfo as updatePaymentRedux } from '../../../../Slice/employeeSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const Editpaymentinfo = () => {
  const paymentInfo1 = useSelector((state) => state.employees);
  const [Empname, setEmpName] = useState("");
  const [paymentInfo, setPaymentInfo] = useState(null);
  useEffect(() => {
    if (paymentInfo1 && paymentInfo1.employees.length > 0) {
      setPaymentInfo(paymentInfo1.employees[0]);
      setEmpName(paymentInfo1.employees[0].register_details.name);
    }
  }, [paymentInfo1]);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(false);
  const [updatePaymentInfo, setUpdatePaymentInfo] = useState({
    bank_name: "",
    account_num: "",
    pan_num: "",
    aadhar_num: "",
    UAN: ""
  })

  useEffect(() => {
    if (paymentInfo && paymentInfo.payment_details) {
      setUpdatePaymentInfo({
        bank_name: paymentInfo.payment_details.bank_name || '',
        account_num: paymentInfo.payment_details.account_num || '',
        pan_num: paymentInfo.payment_details.pan_num || '',
        aadhar_num: paymentInfo.payment_details.aadhar_num || '',
        UAN: paymentInfo.payment_details.UAN || '',
      });
    }
  }, [paymentInfo]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatePaymentInfo((state) => ({
      ...state, [name]: value,
    }))
  }

  const handleSubmit = async () => {
    let newErrors = {}; // Use let to allow reassignment

    setErrors(newErrors);
    if (updatePaymentInfo.bank_name.trim() === "") {
      newErrors.bank_name = "Bank Name is Required"
    }
    const aadhar = String(updatePaymentInfo.aadhar_num);

    if (aadhar.trim() === "") {
      newErrors.aadhar_num = "Aadhar Number is Required"
    }
    if (updatePaymentInfo.pan_num.trim() === "") {
      newErrors.pan_num = "Pan Number is Required"
    }
    if (updatePaymentInfo.UAN.trim() === "") {
      newErrors.UAN = "UAN is Required"
    }
    const accnumber = String(updatePaymentInfo.aadhar_num);

    if (accnumber.trim() === "") {
      newErrors.account_num = "Account Number is Required"
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        setloading(true)
        const response = await UpdateEmployeePaymentApi(paymentInfo.payment_details.id, updatePaymentInfo);
        toast.success('Payment Details Updated Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast',
        })
        Store.dispatch(updatePaymentRedux({ updatedData: response.data.updated_payment }))
      }
      catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key];
            }
          }
          setErrors(newErrors);

        } else {
          toast.error('Payment Details Updated Failed!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false
          })
          // setErrors({
          //   response_error: 'An unexpected error occurred. Please try again later.',
          // });
        }
      }
      finally {
        setloading(false);
      }
    }
  }

  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='addemp-subheader'>
                <div className='addemp-subheader-sub'>
                  {/* <div className='addemp-subheader1'>
                                  

                                <p>Edit Payment Information</p>
                            </div> */}
                  <div className='addemp-subheader2'>
                    <div className='editbasic-stepper'>
                      < FaCircleUser className="editbasic-icon-large" />
                      <p>{Empname}</p>
                    </div>
                  </div>
                </div>

              </div>
              <div className='addemp-line'></div>
              <div className='addemp-maincontent-sub'>
                <div className='addemp-form form-padding'>
                  <div className='addemp-form-sub '>
                    <div className='addemp-form-sub-main'>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Bank Name <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field' placeholder='Enter bank name' name='bank_name' value={updatePaymentInfo.bank_name} onChange={handleInputChange} type='text' />
                          {errors.bank_name && (<div className='error-message-view'>{errors.bank_name}</div>)}
                        </div>
                        <div className='emppersonalinfo-input-group'>
                          <label> Account Number  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' placeholder='Enter account no' name='account_num' value={updatePaymentInfo.account_num} onChange={handleInputChange} type='text' />
                          {errors.account_num && (<div className='error-message-view'>{errors.account_num}</div>)}
                        </div>

                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label>Pan Number <span className='emppersonalinfo-mandatory'>*</span></label>
                          <input className='emppersonalinfo-input-field' placeholder='Enter pan number' name='pan_num' value={updatePaymentInfo.pan_num} onChange={handleInputChange} type='text' />
                          {errors.pan_num && (<div className='error-message-view'>{errors.pan_num}</div>)}
                        </div>


                      </div>
                      <div className='emppersonalinfo-input-row'>
                        <div className='emppersonalinfo-input-group'>
                          <label> Aadhar Number  <span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' placeholder='Enter aadhar number' name='aadhar_num' value={updatePaymentInfo.aadhar_num} onChange={handleInputChange} type='text' />
                          {errors.aadhar_num && (<div className='error-message-view'>{errors.aadhar_num}</div>)}
                        </div>
                      </div>
                      <div className='emppersonalinfo-input-row'>

                        <div className='emppersonalinfo-input-group'>
                          <label>UAN<span className='emppersonalinfo-mandatory'>*</span></label>

                          <input className='emppersonalinfo-input-field' placeholder='Enter UAN number' name='UAN' value={updatePaymentInfo.UAN} onChange={handleInputChange} type='text' />
                          {errors.UAN && (<div className='error-message-view'>{errors.UAN}</div>)}
                        </div>

                      </div>
                      {errors.response_error && (<div className='error-message-view'>{errors.response_error}</div>)}

                      <div className='emppersonalinfo-submitbutton'>
                        <button onClick={handleSubmit} disabled={loading}>
                          {loading ? <span className='spinner'></span> : "Submit"}

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Editpaymentinfo