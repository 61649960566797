import React from 'react';
import './Sidebar.css';
import { RiDashboardLine, RiUserLine, RiFileTextFill, RiCalculatorLine, RiMoneyRupeeCircleLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom'; 
import { CiMoneyBill } from 'react-icons/ci';
import { getUserDetails } from '../../Services/Storage';

const Sidebar = () => {

  const user_details = getUserDetails();

  return (
    <div className='sidebar-container'>
      <div className='sidebar-container-sub'>
        {/* <div className='sidebar-topview'>
          <div className='sidebar-topview-sub'>
            <div className='sidebar-topview-header'>
              <h4>Isaac</h4>
              <CiBellOn size={20} />
            </div>
          </div>
        </div> */}
        <div className='sidebar-body'>
          <div className='sidebar-body-sub'>
            <ul className='sidebar-body-list'>
              <NavLink to="/dashboard" > 
                <li>
                  <span>
                    <RiDashboardLine size={15} />
                    <span>Dashboard</span>
                  </span>
                </li>
              </NavLink>
              {
                user_details.role === 'Company'
                ?
                (
                  <>
                  <NavLink to="/employee" > 
                <li>
                  <span>
                    <RiUserLine size={15} />
                    <span>Employees</span>
                  </span>
                </li>
              </NavLink>
              <NavLink to="/viewleave" > 
                <li>
                  <span>
                    <RiFileTextFill size={15} />
                    <span>Leave</span>
                  </span>
                </li>
              </NavLink>
              <NavLink to="/viewattendance" > 
                <li>
                  <span>
                    <RiCalculatorLine size={15} />
                    <span>Attendance Report</span>
                  </span>
                </li>
              </NavLink>
              <NavLink to="/viewpayroll" > 
                <li>
                  <span>
                    <RiMoneyRupeeCircleLine size={15} />
                    <span>Payroll</span>
                  </span>
                </li>
              </NavLink>
              <NavLink to="/addearningcomp" > 
                                <li>
                                  <span>
                                    <CiMoneyBill size={15} />
                                    <span>Salary Component</span>
                                  </span>
                                </li>
                              </NavLink>
                              <NavLink to="/viewleavecomp" > 
                <li>
                  <span>
                    <RiFileTextFill size={15} />
                    <span>Leave Component</span>
                  </span>
                </li>
              </NavLink>
              </>
                )
                : 
                (
                  <>
                  <NavLink to="/staffLeave" > 
                <li>
                  <span>
                    <RiFileTextFill size={15} />
                    <span>Leave</span>
                  </span>
                </li>
              </NavLink>
              <NavLink to="/viewstaffattendance" > 
                <li>
                  <span>
                    <RiCalculatorLine size={15} />
                    <span>Attendance Report</span>
                  </span>
                </li>
              </NavLink>
              <NavLink to="/viewstaffpayroll" > 
                <li>
                  <span>
                    <RiMoneyRupeeCircleLine size={15} />
                    <span>Payroll</span>
                  </span>
                </li>
              </NavLink>
                  </>
                )
              }
              
              
         
              
              
              {/* <li>
                <button onClick={logout} className='sidebar-link-hide' style={{ border: 'none', background: 'none', padding: 0, fontSize:"14px" }}>
                  <RiMoneyDollarCircleLine size={15} /> <span> Sign Out</span>
                </button>
              </li> */}
            </ul>
          </div>
        </div>
        {/* <div className='sidebar-bottom'>
          You can add additional content or icons for the bottom section if needed 
        </div> */}
      </div>
    </div>
  );
}

export default Sidebar;