import React, { useState } from 'react';
import '../../addingcomp.css'
import { AddSalaryComponentApi } from '../../../../Config/api';
import { getUserDetails } from '../../../../Services/Storage';
import { addSalaryComponent } from '../../../../Slice/salaryComponentSlice';
import { Store } from '../../../../Store';
import { toast } from 'react-toastify';

const AddEarnings = ({show ,close,sendDataToParent}) => {
const userDetails = getUserDetails();
const [errors, setErrors] = useState({});
const [loader , setloader]=useState(false);
const [salarycomponent, setSalaryComponent] = useState({
  component_name:'',
  component : '',
  calculation_type:'flat',
  amount_type:'',
  calculation_code:'basic',
  earning_deduction:'earning',
  user:userDetails.id,
});

const handleInputChange = (event) =>{
  const {name, value} = event.target;
  setSalaryComponent((preValue)=>({
    ...preValue,
    [name] : value,
  }))
}

const handleSubmit = async () =>{

  const fieldData =[{
    name: "component_name",
    type: "input",
    err:"Component Name",
conditions:[
  (value) =>typeof value ==="string",
  (value)=>value.trim() !== "",
  (value)=>/^[a-zA-Z\s]+$/.test(value),
]
  },
{
  name:"component",
  type:"input",
  err:"Component Type",
  conditions:[
    (value)=>typeof value ==="string",
    (value) => value.trim() !== " ",
    (value) =>/^[a-zA-Z\s]+$/.test(value),
  ],
},
{
  name:"amount_type",
  type:"input",
  err:"Amount Type",
  conditions:[
    (value)=>!isNaN(value),
    (value)=>typeof parseFloat(value)==="number",
    (value) =>/^\d+(\.\d+)?$/.test(value),
  ]

},
  ];
  const fieldOrder =[
    'component_name',
    "component",
    "amount_type",
  ]
  const newErrors ={};
  for(const fieldName of fieldOrder){
    const fieldInfo = fieldData.find((field)=>field.name === fieldName);
    if(!fieldInfo){
      continue;
    }
    const fieldType = fieldInfo.type;
    const fieldLabel = fieldInfo.err;
    const value = salarycomponent[fieldName];
    const isFieldValid = fieldInfo.conditions.every((condition)=>typeof condition === "function" ? condition(value):
    condition
  );
  if(!isFieldValid){
    if(value.trim() ===""){
      newErrors[fieldName]= `${fieldLabel} is Required`
    }else{
      newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}`;

    }
    const inputField = document.querySelector(`${fieldType}[name = "${fieldName}"]`);
    if(inputField){
      inputField.focus();
      break;
    }
  }
  }
  setErrors(newErrors);
  if(Object.keys(newErrors).length ===0){

  
  try{
    setloader(true);
      const response =await AddSalaryComponentApi(salarycomponent);
      if (response && response.data && response.data.salary_component) {
        console.log("Received data: ", response.data.salary_component);
        sendDataToParent(response.data.salary_component);
        Store.dispatch(addSalaryComponent(response.data.salary_component));
        setSalaryComponent((preValue)=>({
          ...preValue,
          component_name:'',
          component : '',
          calculation_type:'flat',
          amount_type:'',
          calculation_code:'basic',
          earning_deduction:'earning',
          user:userDetails.id,
        }))
        close();
        toast.success ('Leave Component Added Sucessfully!',{
          position:"top-center",
          autoClose:2000,
          closeButton:false,
          className:'custom_toast'
        })
      }else{
console.log("error")
      }
  }
  catch (error) {
    const errorMessage =
      error.response && error.response.data && error.response.data.data
        ? error.response.data.data
        : "Add Earnings Failed. Please try again.";
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      response_error: errorMessage,
    }));
  
    toast.error("Add Earnings Failed. Please try again.", {
      position: "top-center",
      autoClose: 2000,
      closeButton: false,
    });
  }finally{
    setloader(false);

  }
  
}
}


if (!show) return null
return (
<div className='addsalrcomp-section'>
<div className='addsalrcomp-container'>
<div className='addsalrcomp-container-div1'>
<span className='uploadexcel-close' onClick={close}>&times;</span>
<div className='popup-container-sub'>
<div className='add-component-heading'>
<p>Add Earning</p>
</div>
<div className='addleave-strightline'></div> 
<div className='sal-comp-form-div'>
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Component Name <span className='sal-comp-input-important'> * </span> </span>
    <input type='text' onChange={handleInputChange} name='component_name'className='sal-comp-input' />
 
 {
  errors.component_name &&(<div className='error-message'>{errors.component_name}</div>)
 } 
 </div>
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Component Type <span className='sal-comp-input-important'> * </span> </span>
    <input type='text' onChange={handleInputChange}  name='component'className='sal-comp-input' />
  {
    errors.component && <div className='error-message'>{errors.component}</div>
  }
  </div>
  <div className='sal-comp-input-div'>
  <span className='sal-comp-input-div-span-text' > Calculation Type <span className='sal-comp-input-important'> * </span> </span>

    <select className='sal-comp-select-box' name='calculation_type' onChange={handleInputChange}>

      <option value="ctc">Percentage of CTC</option>
      <option value="basic">Percentage of Basic</option>
      <option value="flat">Flat Amount</option>
      </select>
      
  </div>
    {/* <div className='sal-comp-input-div'>
      <span className='sal-comp-input-div-span-text' style={{fontSize:"14px"}}> Calculation Type <span className='sal-comp-input-important'> * </span> </span>
      <span className='sal-comp-input-span-calc'>
        <span> 
          <span> Percentage of CTC </span>
          <input type='radio' name='calculation_type' value='ctc' onChange={handleInputChange} checked={salarycomponent.calculation_type === 'ctc'} />
        </span>
        <span> 
          <span> Percentage of Basic </span>
          <input type='radio' name='calculation_type' value='basic' onChange={handleInputChange} checked={salarycomponent.calculation_type === 'basic'} />
        </span>
        <span> 
          <span> Flat Amount </span>
          <input type='radio' value='flat' name='calculation_type' onChange={handleInputChange} checked={salarycomponent.calculation_type === 'flat'} />
        </span>
      </span>
    </div> */}
  {salarycomponent.calculation_type === 'flat'?(
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Enter Amount <span className='sal-comp-input-important'> * </span></span>
    {/* <RiMoneyRupeeCircleLine className='addsalrcomp-input-icon'/> */}
    <input type='text' onChange={handleInputChange} name='amount_type'className='sal-comp-input' />
  {
    errors.amount_type && (<div className='error-message'>{errors.amount_type}</div>)
  }
  </div>
  ):(
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Enter Percentage of {salarycomponent.calculation_type} <span className='sal-comp-input-important'> * </span> </span>
    <input type='text' onChange={handleInputChange} name='amount_type' className='sal-comp-input' />
 
 {
  errors.amount_type && (
    <div className='error-message'>{errors.amount_type}</div>
  )
 } </div>
  )}
{
  errors.response_error && (
    <div className='error-message-view salry-comp-err'>
      {errors.response_error.component}
    </div>
  )
}


  <div className='sal-comp-button-div'>
  <button onClick={handleSubmit} disabled={loader} className='popup-button-cls'>{ loader ? <span className='spinner'></span>:"submit"}</button>
  </div>
</div>


{/* <div className='popup-content-viewing-addsalrcomp'>
<div className='popup-content-viewing-addsalrcomp-div1'>
<div className='addsalrcomp-inputfield'>
<label> Earning Type<span className='registeration-mandatory'> *</span> </label>
<input className='input-earnings' type='text'/>
</div>
<div className='addsalrcomp-inputfield'>
<label> Earning Name<span className='registeration-mandatory'> *</span> </label>
<input className='input-earnings' type='text'/>
</div>
<div className='addsalrcomp-inputfield'>
<label> Name in Payslip<span className='registeration-mandatory'> *</span> </label>
<input className='input-earnings' type='text'/>
</div>
<div className='addsalrcomp-inputfield'>
<label>Calculation Type </label>
<span className='radio-button-span'>
<span className='radio-button-span-label'>
<span>Flat Amount</span>
<input type="radio" id='flat' name='calculationType'   checked={amountType === 'flat'}
value='flat'

onChange={handleamountchange}
/>
</span>
<span className='radio-button-span-label'>
<span>Percentage of CTC</span>
<input type="radio" id='percentage'name='calculationType'  
checked={amountType === 'percentage'}
value='percentage'
onChange={handleamountchange}
/>
</span>

</span>
{amountType === 'flat'?(
<div className='addsalrcomp-inputfield input-radio'>
<label> Enter Amount<span className='registeration-mandatory'> *</span> </label>
<div className='addsalrcomp-input-view'>
<RiMoneyRupeeCircleLine className='addsalrcomp-input-icon'/>
<input className='input-earnings-1' type='text'/>
</div></div>
):(
<div className='addsalrcomp-inputfield input-radio'>
<label> Enter percentage<span className='registeration-mandatory'> *</span> </label>
<div className='addsalrcomp-input-view'>
<RiPercentLine className='addsalrcomp-input-icon'/>
<input className='input-earnings-1' type='text'/>
</div>

</div>
)

}
</div>


<div className='popup-button'>
<button >submit</button>
</div>
</div>
</div> */}
</div>

</div>
</div>
</div>
)
}

export default AddEarnings