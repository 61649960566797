import React, { useState, useEffect, useRef ,useCallback } from 'react';
import './Addleave.css';
import DatepickerComp from '../../Component/DatepickerComp';
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
import { AddLeave, FetchEmployee } from '../../../Config/api';
import { CiSearch } from 'react-icons/ci';
import { toast } from 'react-toastify';
import { addLeave } from '../../../Slice/leaveSlice';
import { Store } from '../../../Store';

const Addleave = ({ show, onclose }) => {
  const [total_days, setNumberofDays] = useState(0);
  const [from_date, setFromDate] = useState('');
  const [to_date, setToDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const user_details = getUserDetails();
  const [searchOpen, setSearchOpen] = useState(false);
  const [showleavetype, setleavetype] = useState(false);
  const [search, setSearchword] = useState("");
  const [employees, setEmployees] = useState([]);
  const [employeename, setEmployeeName] = useState('');
  const dropdownRef = useRef(null);
  const leaveTypeDropdownRef = useRef(null);
  const [eligibleLeaveDetails, setEligibleLeaveDetails] = useState([]); // New state for eligible leave details
  const [isEmployeeSelected, setIsEmployeeSelected] = useState(false); // New state for tracking employee selection
  const company_details = getCompanyDetails() || { company_id: '' };

  const [formData, setFormData] = useState({
    empid: '',
    employeename: '',
    leave_type: '',
    reason: '',
    from_date: '',
    to_date: '',
    total_days: 0,
    apply_date: '',
    user: user_details.id,
    register: "",
    company: company_details.company_id || ''
  });

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setSearchOpen(false);
      }

      if (
        leaveTypeDropdownRef.current &&
        !leaveTypeDropdownRef.current.contains(event.target)
      ) {
        setleavetype(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleinputClick = () => {
    setleavetype(!showleavetype);
  }

  const handleSelectEmployee = (employee) => {
    setSearchOpen(false);
    setleavetype(false);
    setSearchword('');
    setEmployeeName(employee.name);
    setFormData((prevData) => ({
      ...prevData,
      empid: employee.employee_id,
      employeename: employee.name,
      register: employee.register_id

    }));
    setEligibleLeaveDetails(employee.eligible_leave_details || []);
    setIsEmployeeSelected(true);

  };

  const handleleavetype = (leavetype) => {
    setleavetype(false)
    setFormData((prevData) => ({
      ...prevData,
      leave_type: leavetype.id,
      leave_name: leavetype.leave_type,
    }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchEmployeeList =useCallback( async () => {
    try {
      const response = await FetchEmployee(`?register_id=${user_details.id}&search=${search}`);
      setEmployees(response.data.emp_name_id);
    } catch (error) {
      console.error("Error fetching employee list:", error);
    }
  },[search,user_details.id]);



  useEffect(() => {
    fetchEmployeeList();
  }, [fetchEmployeeList]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      from_date,
      to_date,
      total_days: total_days,
    }));
  }, [from_date, to_date, total_days]);

  const handleSearch = (event) => {
    setSearchword(event.target.value);
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (formData.employeename.trim() === "") {
      newErrors.employeename = "Employee Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.employeename)) {
      newErrors.employeename = "Name must contain only alphabetic characters and spaces";
    }

    if (formData.reason.trim() === '') {
      newErrors.reason = 'Leave Reason is required';
    }
    if (formData.from_date.trim() === '') {
      newErrors.from_date = 'Please select a leave date.';
    }

    if (formData.apply_date.trim() === '') {
      newErrors.apply_date = 'Applying Date is required';
    }
    if (formData.empid.trim() === '') {
      newErrors.empid = 'Employee Id is required';
    }
    if (!formData.leave_type) {
      newErrors.leave_type = 'Leave Type is required';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        console.log("Form Data:", formData);
        const response = await AddLeave(formData);
        console.log("response leave ", response.staff_leave_details[0]);
        Store.dispatch(addLeave(response.staff_leave_details[0]));
        if (response) {
          toast.success('Leave added successfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
          });
          setFormData({
            empid: '',
            employeename: '',
            leave_type: "",
            leave_name: '',
            reason: '',
            from_date: '',
            to_date: '',
            total_days: 0,
            apply_date: '',
            register: "",
          });

          setEmployeeName('');
          setFromDate('');
          setToDate('');
          setNumberofDays(0);
          onclose();



        }

      } catch (error) {
        if (error.response) {
          const newErrors = {};
          newErrors.response_error = error.response.data.data;
          setErrors(newErrors);
        } else {
          toast.error('Failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,

          });
          const newErrors = {
            response_error: [],
          };
          for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data.errors[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }
      } finally {

        setLoading(false);
      }
    }
  };

  if (!show) return null;

  return (
    <div className='add-leave-section'>
      <div className='addleave-container'>
        <span className='uploadexcel-close' onClick={onclose}>&times;</span>
        <div className='addleave-container-sub'>
          <div className='addleave-heading'>
            <p>Add Leave</p>
          </div>
          <div className='addleave-strightline'></div>
          <div className='addleave-input'>
            <div className='addleave-input-line1'>
              <div className='addleave-input-group'>
                <label>Employee ID</label>
                <div ref={dropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${searchOpen ? "active" : ""}`}>
                  <div onClick={handleSearchClick}>
                    <input
                      type='text'
                      placeholder='Search employee id or employee name'
                      onChange={handleInputChange}
                      name='empid'
                      value={formData.empid}
                      readOnly
                    />
                  </div>
                  {searchOpen && (
                    <div className='invoice-sales-grid-div-dropdown'>
                      <div className='invoice-dropdown-search'>
                        <CiSearch className="las la-search" />
                        <input
                          type="text"
                          placeholder='Search Employees'
                          name="search"
                          onChange={handleSearch}
                        />
                      </div>
                      <ul>
                        {employees
                          .filter(employee =>
                            employee.name && employee.name.toLowerCase().includes(search.toLowerCase())
                          )
                          .map(employee => (
                            <li
                              key={employee.id}
                              className='sal_block'
                              onClick={() => handleSelectEmployee(employee)}
                            >
                              {employee.name}
                            </li>
                          ))}
                        {employees.length === 0 && (
                          <li className='no-data'>No data found</li>
                        )}
                      </ul>
                    </div>
                  )}
                  {errors.empid && (
                    <div className="error-message-view">{errors.empid}</div>
                  )}
                </div>
              </div>
              <div className='addleave-input-group'>
                <label>Employee Name</label>
                <input type='text' onChange={handleInputChange} value={employeename} name='employeename' />
                {errors.employeename && (
                  <div className="error-message-view">{errors.employeename}</div>
                )}
              </div>
              <div className='addleave-input-group'>
                <label>Applying Date</label>
                <input type='date' value={formData.apply_date} onChange={handleInputChange} name="apply_date" />
                {errors.apply_date && (
                  <div className="error-message-view">{errors.apply_date}</div>
                )}
              </div>
            </div>
          </div>
          <div className='addleave-input2'>
            <div className='addleave-input-line2'>
              <div className='addleave-input-group'>
                <label>Leave Type</label>
                <div ref={leaveTypeDropdownRef} className={`invoice-grid-div invoice-span-div invoice-sales-div ${showleavetype ? "active" : ""}`}>
                  <div onClick={handleinputClick}>
                    <input
                      type='text'
                      placeholder='Select Leave Type'
                      onChange={handleInputChange}
                      name='leave_type'
                      value={formData.leave_name}
                      readOnly
                      disabled={!isEmployeeSelected}

                    />
                  </div>
                  {showleavetype && (
                    <div className='invoice-sales-grid-div-dropdown'>
                      <ul>
                        {Array.isArray(eligibleLeaveDetails) && eligibleLeaveDetails.length > 0 ? (
                          eligibleLeaveDetails.map(leaveType => (
                            <li
                              key={leaveType.id}
                              className='sal_block'
                              onClick={() => handleleavetype(leaveType)}
                            >
                              {leaveType.leave_name}
                            </li>
                          ))
                        ) : (
                          <li className='sal_block'>No Data Found</li>
                        )}
                      </ul>
                    </div>
                  )}
                  {errors.leave_type && (
                    <div className="error-message-view">{errors.leave_type}</div>
                  )}
                </div>
              </div>
              <div className='addleave-input-group'>
                <label>Leave Reason</label>
                <textarea name='reason' value={formData.reason} onChange={handleInputChange}></textarea>
                {errors.reason && (
                  <div className="error-message-view">{errors.reason}</div>
                )}
              </div>
            </div>
          </div>
          <div className='addleave-input2'>
            <div className='addleave-input-line2'>
              <div className='addleave-input-group'>
                <label>Select Leave Dates</label>
                <DatepickerComp
                  setNumberOfDays={setNumberofDays}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                />
                {errors.from_date && (
                  <div className="error-message-view">{errors.from_date}</div>
                )}

              </div>
              <div className='addleave-input-group'>
                <label>No Of Days</label>
                <input type='text' name='noofdays' value={total_days} readOnly />
              </div>
            </div>
          </div>
          <div className='response-message-view'>
            {errors.response_error && (
              <div className="error-message-view">{errors.response_error}</div>
            )}
          </div>

          <div className='addleave-buttons'>
            <div className='addleave-submit-button'>
              <button onClick={handleSubmit} disabled={loading}>
                {loading ? <span className="spinner"></span> : 'Submit'}

              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addleave;
