import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const companySlice = createSlice({
    name : 'company',
    initialState,
    reducers:{
        addCompany(state, action)
        {
                
        },
        setCompanies(state, action) {
            return action.payload;
        }
    }
})

export const { addCompany, setCompanies } = companySlice.actions;
export default companySlice.reducer;