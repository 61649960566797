import { createSlice } from "@reduxjs/toolkit";
const initialState= [];
const designationSlice = createSlice({
    name : "designation",
    initialState,
    reducers:{
        addDesignation(state, action)
        {
state.push(action.payload)
        },
        setDesignations(state, action)
        {
            return action.payload;
        },
        updatedesignationcomp(state,action)
        {
            const updatedComponent = action.payload;
            return state.map((component)=>
            component.id ===updatedComponent.id
            ?{...component, ...updatedComponent}
    :component
            )
        }
    }
});

export const {addDesignation, setDesignations ,updatedesignationcomp} = designationSlice.actions;
export default designationSlice.reducer;