import React, { useState, useEffect } from 'react';
import './Editorgprofile.css';
import uploadlogo from '../../../../Images/Register/logoupload';
import { getUserDetails } from '../../../../Services/Storage';
import api_backend, { UpdateCompanyDetails } from '../../../../Config/api';
import { toast } from 'react-toastify';


const Editorgprofile = ({ show, close, editData, updateData }) => {
  const userDetails = getUserDetails();
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setleading] = useState(false)
  const [addorgprofile, setorpprofile] = useState({
    company_name: '',
    company_email: '',
    address_line1: '',
    address_line2: '',
    company_phone_num: '',
    company_logo: '',
    city: '',
    state: '',
    pincode: '',
    user: userDetails.id,

  })
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        console.log(file);
        setorpprofile((state) => ({
          ...state,
          [name]: file,
        }));
        setSelectedImage(URL.createObjectURL(file));

      } else {
        setSelectedImage("")
      }
    } else {
      setorpprofile((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    console.log("editdata", editData)
    if (editData) {
      setorpprofile({
        company_name: editData.company_name || '',
        company_email: editData.company_email || '',
        address_line1: editData.address_line1 || '',
        address_line2: editData.address_line2 || '',
        company_phone_num: editData.company_phone_num || '',
        company_logo: editData.company_logo || '',
        city: editData.city || '',
        state: editData.state || '',
        pincode: editData.pincode || '',
        user: userDetails.id,



      })
    }
  }, [editData, userDetails.id])


  const handleSubmit = async () => {
    const fieldData = [
      {
        name: "company_name",
        type: "input",
        err: "Company Name",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "company_email",
        type: "input",
        err: "Company Email",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
          (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), // Correct email regex
        ],
      },
      {
        name: "address_line1",
        type: "input",
        err: "Address Line1",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "address_line2",
        type: "input",
        err: "Address Line2",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "company_phone_num",
        type: "input",
        err: "Company Mobile No",
        conditions: [
          (value) => typeof value === "string" && value.trim() !== "",
          (value) => /^\d+$/.test(value), // Ensure phone number contains only digits
        ],
      },
      {
        name: "city",
        type: "input",
        err: "City",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "state",
        type: "input",
        err: "State",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      {
        name: "pincode",
        type: "input",
        err: "Pincode",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
        ],
      },
      // {
      //   name: "company_logo", // For the image field
      //   type: "file",
      //   err: "Company Logo",
      //   conditions: [
      //     (file) => file instanceof File, // Check if the input is a file
      //     (file) => file.type.startsWith("image/"), // Ensure it's an image
      //     (file) => file.size <= 2 * 1024 * 1024, // Ensure size <= 2MB
      //   ],
      // },
    ];

    const fieldOrder = [
      "company_name",
      "company_phone_num",
      "company_email",
      "address_line1",
      "address_line2",
      "city",
      "state",
      "pincode",
      // "company_logo", // Validate image last
    ];

    let newErrors = {}; // Changed to let
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) {
        continue;
      }
      const fieldType = fieldInfo.type;
      const fieldLabel = fieldInfo.err;
      const value = fieldName === "company_logo" ? addorgprofile[fieldName] : String(addorgprofile[fieldName] || ""); // Handle image field separately
      const isFieldValid = fieldInfo.conditions.every((condition) =>
        typeof condition === "function" ? condition(value) : condition
      );

      if (!isFieldValid) {
        if (fieldName === "company_logo") {
          if (!value) {
            newErrors[fieldName] = `${fieldLabel} is Required`;
          } else if (!value.type.startsWith("image/")) {
            newErrors[fieldName] = `Please upload a valid image file.`;
          } else if (value.size > 2 * 1024 * 1024) {
            newErrors[fieldName] = `Image size should be less than 2MB.`;
          }
        } else {
          if (value.trim() === "") {
            newErrors[fieldName] = `${fieldLabel} is Required`;
          } else {
            newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}.`;
          }
        }
        const inputField = document.querySelector(`${fieldType}[name="${fieldName}"]`);
        if (inputField) {
          inputField.focus();
          break;
        }
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setleading(true)
        const data = { ...addorgprofile };
        if (selectedImage) {
          data.company_logo = addorgprofile.company_logo;
        } else {
          delete data.company_logo;
        }
        const response = await UpdateCompanyDetails(editData.id, data);
        if (response) {
          updateData(response.data.updated_designation);
          close();
          toast.success("Organization Profile Updated Successfully!", {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: "custom_toast",
          });
          setSelectedImage(null)
        }
      } catch (error) {
        if (error.response && error.response.data) {
          const errorData = error.response.data.data;
          newErrors = {};
          console.log(errorData, "erfiuedofjueoirhjefrhjekhk")
          for (const key in errorData) {
            if (errorData.hasOwnProperty(key)) {
              newErrors[key] = errorData[key]; // Assign the specific error message to the respective field
            }
          }

          setErrors(newErrors);
        } else {
          toast.error('organization Registration failed. Please try again.', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'

          });

          // Handle generic errors
          setErrors({
            response_error: 'An unexpected error occurred. Please try again later.',
          });
        }

      } finally {
        setleading(false)

      }
    }
  };

  if (!show) return null;

  return (
    <div className='editorg-section'>
      <div className='editorg-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>

        <div className='editorg-container-sub'>
          <div className='editorg-heading'>
            Edit Organization Profile
          </div>
          <div className='editorg-main'>


            <div className='addleave-strightline'></div>

            <div className='editorg-form-fields'>

              <label htmlFor="company-logo" className='companyinfo-label-view'>Company Logo</label>
              <div className='companyinfo-imgupload'>
                <div className='companyinfo-imgupload-sub' onClick={handleInputChange}>
                  <div>
                    {selectedImage ? (
                      <img
                        id="preview-image"
                        name='company-logo'
                        className='companyinfo-img-field-view'
                        src={selectedImage}
                        alt="Uploaded"
                      />
                    ) : addorgprofile.company_logo ? (

                      (<img
                        id="preview-image"
                        className='companyinfo-img-field-view'
                        src={`${api_backend.BACKEND_API}${addorgprofile.company_logo}`}
                        alt="Uploaded"
                      />)
                    ) :

                      (
                        <img
                          id="preview-image"
                          className='companyinfo-img-field'
                          src={uploadlogo.upload_logo}
                          alt="Uploaded"
                        />
                      )}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name='company_logo'
                    onChange={handleInputChange}
                    style={{ width: "100%", height: "100%", position: "absolute", opacity: "-0.5", fontSize: "1px" }}
                  />
                </div>

              </div>
              <br />
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='companyName'>Company Name</label>
                  <input
                    id='companyName'
                    name='company_name'
                    type='text'
                    value={addorgprofile.company_name}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.company_name && (<div className='error-message'>{errors.company_name}</div>)
                  }
                </div>

                <div className='companyinfo-input-group'>
                  <label htmlFor='companyPhone'>Company Phone</label>
                  <input
                    id='companyPhone'
                    name='company_phone_num'
                    type='text'
                    value={addorgprofile.company_phone_num}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.company_phone_num && (<div className='error-message'>{errors.company_phone_num}</div>)
                  }
                </div>
              </div>
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='companyEmail'>Company Email</label>
                  <input
                    id='companyEmail'
                    name='company_email'
                    value={addorgprofile.company_email}
                    type='text'
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.company_email && (<div className='error-message'>{errors.company_email}</div>)
                  }
                </div>

              </div>
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='addressLine1'>Address Line 1</label>
                  <input
                    id='addressLine1'
                    name='address_line1'
                    type='text'
                    value={addorgprofile.address_line1}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.address_line1 && (<div className='error-message'>{errors.address_line1}</div>)
                  }
                </div>
                <div className='companyinfo-input-group'>
                  <label htmlFor='addressLine2'>Address Line 2</label>
                  <input
                    id='addressLine2'
                    name='address_line2'
                    type='text'
                    value={addorgprofile.address_line2}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.address_line2 && (<div className='error-message'>{errors.address_line2}</div>)
                  }
                </div>

              </div>
              <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                  <label htmlFor='city'>City</label>
                  <input
                    id='city'
                    name='city'
                    type='text'
                    value={addorgprofile.city}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.city && (<div className='error-message'>{errors.city}</div>)
                  }
                </div>
                <div className='companyinfo-input-group'>
                  <label htmlFor='state'>State</label>
                  <input
                    id='state'
                    name='state'
                    type='text'
                    value={addorgprofile.state}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.state && (<div className='error-message'>{errors.state}</div>)
                  }
                </div>
                <div className='companyinfo-input-group'>
                  <label htmlFor='pincode'>Pincode</label>
                  <input
                    id='pincode'
                    name='pincode'
                    type='text'
                    value={addorgprofile.pincode}
                    className='companyinfo-input-field'
                    onChange={handleInputChange}
                  />
                  {
                    errors.pincode && (<div className='error-message'>{errors.pincode}</div>)
                  }
                </div>
              </div>

              <div className='companyinfo-div-btn'>
                <button type='button' onClick={handleSubmit}> {loading ? 'loader' : 'Submit'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editorgprofile;
