export const formatTime = (time) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':').map(Number);
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};
export const convertTo12HourFormat = (time24) => {
  if (!time24) return ''; // Handle undefined or null input
  const [hours, minutes] = time24.split(':');
  const hours12 = (+hours % 12 || 12).toString().padStart(2, '0');
  return `${hours12}:${minutes}`;
};




// export const convertToAmPm = (time24) => {
//   if (!time24) return ''; // Handle undefined or null input
//   const [hours, minutes, seconds] = time24.split(':');
//   const period = +hours < 12 ? ' AM' : ' PM';
//   const hours12 = (+hours % 12 || 12).toString().padStart(2, '0');
//   return `${hours12}:${minutes} ${period}`;
// };

