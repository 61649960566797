import React ,{useState,useEffect}from "react";
import logo from "../Images/Logo/Logo";
import websiteImages from "./WebsiteImages";
import "./css/home.css";
import "./css/mobileview.css";
import { Link } from "react-router-dom";

const Home = () => {


  const [isScrolled, setIsScrolled] = useState(false);
useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
          
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return (
    <>
      <div className="staffin_heade_main_div">
        <div className={`staffin_div_main_logo ${isScrolled ? 'scrolled' : ''}`} >
          <div className="staffin_add_logos">
            <img src={logo.logo_img} alt="" />
          </div>
          <div className="staffin_login_btns">
            <button>
              <Link to="/login" style={{ color: "#fff", textDecoration: "none" }}>
                Login
              </Link>
            </button>
          </div>
        </div>
        <div className="staffin_header_div">
          <div className="header-overlay">
            <h1>Where Efficiency Meets Accountability STAFFIN</h1>
            <p>
              "Empower your workforce with precision attendance management."
              Explore More.
            </p>
            <div className="staffin_playstor_pic">
              <img src={websiteImages.playstore} alt="" />
            </div>
          </div>
{/* 
          <div className="staffin_add_rockied_pic">
            <img src={websiteImages.image2} alt="" />
          </div> */}
        </div>
      </div>
      <div className="staffin_clrss_div">

      <div className="staffin_contact_main_div">
          <div className="staffin_div">
            <h2>Attendance Management</h2>
            <p>
             What is Attendance Management?
            </p>
          </div>
          <div className="staffin_contact_div_conts">
            <div className="staffin_contts_div_conts">
              
              <div className="staffin_att_conts_div">
                <p>
                  {" "}
                
                </p>
                <p>

                Attendance management refers to the process of tracking and managing employee attendance within an organization. This involves monitoring when employees start and end their workdays, as well as tracking absenteeism, lateness, leave, and overtime. The goal is to ensure employees adhere to their assigned schedules while providing accurate data for payroll processing, performance analysis, and legal compliance.                </p>
             <p>Effective attendance management helps organizations maintain productivity and reduce payroll errors. It also involves managing leave requests, monitoring absenteeism patterns, and generating reports that support decision-making in HR and management, ensuring compliance with labor laws and organizational policies.</p>
              </div>
              <div className="staffin_attendance_add_pic">
                <img src={websiteImages.About_tt} alt="" />
              </div>
            </div>
          </div>
        </div>
        <hr className="staffin_line" />

      <h2>All Solutions In One Platform</h2>
      <p>All-in-One Platform for Attendance, Payroll, and Employee Management.</p>
<br/>
<br/>
        <div className="staffin_clrss_first">
<div className="staffin_clrss_contain">
  <div className="staffin_first_img">
  <div className="staffin_first_img-sub">
    <img src={websiteImages.payroll} alt="img"/>

    </div>
  </div>
  <div className="staffin_first_text" >
    <h3>Payroll</h3>
  Payroll refers to the process of calculating and distributing wages, salaries, bonuses, and deductions for employees. It involves tracking work hours, managing tax withholdings, processing payments, and ensuring compliance with labor laws.
  </div>
</div>

<div className="staffin_clrss_contain">
  <div className="staffin_first_img">
  <div className="staffin_first_img-sub">
    <img src={websiteImages.emplo}alt="img" />

    </div>
  </div>
  <div className="staffin_first_text" >
    <h3>Employee</h3>

    An employee works for a company in exchange for wages or salary. They follow company policies, receive benefits, and contribute to operations. Employees are key to achieving business goals and overall success. </div>
</div>

<div className="staffin_clrss_contain">
  <div className="staffin_first_img">
    <div className="staffin_first_img-sub">
    <img src={websiteImages.attend} alt="img"/>

    </div>

  </div>
  <div className="staffin_first_text" >
    <h3>Attendance</h3>
    Attendance tracks employee presence, absences, and time worked. It ensures accurate records for payroll, performance, and management. Efficient tracking helps maintain productivity and compliance.</div>
</div>
        </div>
        <hr className="staffin_line" />

        <div className="staffin_about_main_div">
          <div className="staffin_aboute_div">
            <h2>About Us</h2>
            <p>
              At Sea Sense Staffin, we believe in the power of efficient
              workforce management.
            </p>
          </div>
          <div className="staffin_about_div_conts">
            <div className="staffin_aboute_div_conts_1">
              <div className="staffin_div_add_pic">
                <img src={websiteImages.about} alt="" />
              </div>
              <div className="staffin_div_contents_div">
                <p>
                  Our mission is to provide businesses with precise and reliable
                  attendance management solutions that drive productivity and
                  accountability. With our innovative app, available on both the
                  App Store and Google Play, we empower companies to streamline
                  their operations and enhance employee engagement. Founded on
                  the principles of cutting-edge technology and user-friendly
                  design, Sea Sense Staffin aims to transform the way businesses
                  manage their teams. Our dedicated team of experts works
                  tirelessly to ensure that our clients receive top-notch
                  service and support, making workforce management a seamless
                  experience. Join us on our journey to revolutionize workforce
                  management and discover how Sea Sense Staffin can help your
                  business thrive.
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="staffin_line" />

        <div className="staffin_service_main_div">
          <div className="staffin_service_div">
            <h2>Our Service</h2>
            <p>
              At Sea Sense Staffin, we believe in the power of efficient
              workforce management.
            </p>
          </div>
          <div className="staffin_service_div_conts">
            <div className="staffin_div_main">
              <div className="staffin_card_main_div">
                <div className="staffin_card_div_1">
                  <span class="pricing">
                    <span>
                      i <small></small>
                    </span>
                  </span>
                  <h2>Attendance Management</h2>
                  <p>
                    Our state-of-the-art attendance management system ensures
                    accurate tracking of employee hours.
                  </p>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>{" "}
                    <h3>
                      <span>20</span> team members
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                      Plan <span>team meetings</span>
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>File sharing</h3>
                  </div>
                  <div className="staffin_service_btns">
                    <button>
                      <Link
                        to=""
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        Click Here
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="staffin_card_main_div">
                <div className="staffin_card_div_1">
                  <span class="pricing">
                    <span>
                      i <small></small>
                    </span>
                  </span>
                  <h2>Attendance Management</h2>
                  <p>
                    Our state-of-the-art attendance management system ensures
                    accurate tracking of employee hours.
                  </p>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                      <span>20</span> team members
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                      Plan <span>team meetings</span>
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>File sharing</h3>
                  </div>
                  <div className="staffin_service_btns">
                    <button>
                      <Link
                        to=""
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        Click Here
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
              <div className="staffin_card_main_div">
                <div className="staffin_card_div_1">
                  <span class="pricing">
                    <span>
                      i <small></small>
                    </span>
                  </span>
                  <h2>Attendance Management</h2>
                  <p>
                    Our state-of-the-art attendance management system ensures
                    accurate tracking of employee hours.
                  </p>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                      <span>20</span> team members
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>
                      Plan <span>team meetings</span>
                    </h3>
                  </div>
                  <div className="dd">
                    <span class="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <h3>File sharing</h3>
                  </div>
                  <div className="staffin_service_btns">
                    <button>
                      <Link
                        to=""
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        Click Here
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="staffin_line" />
        <div className="staffin_contact_main_div">
          <div className="staffin_div">
            <h2>Contact Us</h2>
            <p>
              At Sea Sense Staffin, we believe in the power of efficient
              workforce management.
            </p>
          </div>
          <div className="staffin_contact_div_conts">
            <div className="staffin_contts_div_conts">
              
              <div className="staffin_conta_conts_div">
                <p>
                  {" "}
                  We'd love to hear from you! Whether you have a question about
                  our services, need assistance with our app, or want to provide
                  feedback, our team is here to help.
                </p>
                <p>
                  We're here to help! Whether you have a question about our
                  services, need assistance with a feature, or just want to
                  provide feedback, we're always ready to assist. Reach out to
                  us through any of the methods below, and we'll get back to you
                  as soon as possible. Your satisfaction is our top priority,
                  and we're committed to ensuring you have the best experience
                  possible. Additionally, you can follow us on social media for
                  updates, news, and special promotions. Join our community and
                  stay connected with the latest developments and offerings from
                  our team.
                </p>
                <p>
                  Thank you for choosing us. We look forward to hearing from
                  you!
                </p>
                <p>Email: support@seasensestaffin.com</p>
              </div>
              <div className="staffin_contact_add_pic">
                <img src={websiteImages.contact} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="staffin_footer_main_div">
        <div className="staffin_footer_div">
          <h2>CopyRight 2024-2025 SEA SENSE | All Right Reserved</h2>
        </div>
      </div>
    </>
  );
};

export default Home;
