import React, { useState } from 'react'
import '../../Assets/popup.css'
import { getUserDetails } from '../../Services/Storage'
import { AddLeaveComponet } from '../../Config/api';
import { toast } from 'react-toastify';
import { Store } from '../../Store';
import { addLeavecomponent } from '../../Slice/leaveComponentSlice';

const Addleavecomponent = ({ show, close, sendDataToParent }) => {
  const [errors, setErrors] = useState({});
  const userDetails = getUserDetails();
  const [loading, setloading] = useState(false)
  const [addleavecomp, setleavecomp] = useState({
    leave_type: '',
    leave_name: '',
    leave_deduct: '',
    earning_deduction: '',
    user: userDetails.id,


  })

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setleavecomp((preValue) => ({
      ...preValue,
      [name]: value,
    }))
  }
  const handleSubmit = async () => {
    const fieldData = [{
      name: "leave_type",
      type: "input",
      err: "Leave Type",
      conditions: [
        (value) => typeof value === "string",
        (value) => value.trim() !== "",
        (value) => /^[a-zA-Z\s]+$/.test(value),
      ]
    },
    {
      name: "leave_name",
      type: "input",
      err: "Leave Name",
      conditions: [
        (value) => typeof value === "string",
        (value) => value.trim() !== "",

        (value) => /^[a-zA-Z\s]+$/.test(value),]
    },
    {
      name: 'leave_deduct',
      type: 'select',
      err: 'Leave Deduction Type',
      conditions: [
        (value) => value !== '',
      ],
    },
    {
      name: 'earning_deduction',
      type: 'select',
      err: 'Salary Type',

      conditions: [
        (value) => value !== '',
      ]
    }

    ]
    const fieldOrder = [
      "leave_type",
      "leave_name",
      "leave_deduct",
      "earning_deduction",
    ]
    const newErrors = {};
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) {
        continue;
      }
      const fieldType = fieldInfo.type;
      const fieldLabel = fieldInfo.err;
      const value = addleavecomp[fieldName];
      const isFieldValid = fieldInfo.conditions.every((condition) => typeof condition === "function" ? condition(value) :
        condition
      );
      if (!isFieldValid) {
        if (value.trim() === "") {
          newErrors[fieldName] = `${fieldLabel} is Required`
        } else {
          newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}`;

        }
        const inputField = document.querySelector(`${fieldType}[name = "${fieldName}"]`);
        if (inputField) {
          inputField.focus();
          break;
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {


      try {
        setloading(true)
        const response = await AddLeaveComponet(addleavecomp);
        console.log(response)
        sendDataToParent(response.data.leave_component);
        Store.dispatch(addLeavecomponent(response.data.leave_component))
        setleavecomp((preValue) => ({
          ...preValue,
          leave_type: '',
          leave_name: '',
          leave_deduct: '',
          earning_deduction: '',
          user: userDetails.id,
        }))
        close();
        toast.success('Earnings Added Sucessfully!', {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
          className: 'custom_toast'
        })
      } catch (error) {
        const errorMessage =
          error.response && error.response.data && error.response.data.data
            ? error.response.data.data
            : "Add Leave Component Failed. Please try again.";

        setErrors((prevErrors) => ({
          ...prevErrors,
          response_error: errorMessage,
        }));

        toast.error("Add Leave Component Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
        console.log("error")
      } finally {
        setloading(false)
      }
    }
  }
  if (!show) return null;
  return (
    <div className='addsalrcomp-section'>
      <div className='addsalrcomp-container'>
        <div className='addsalrcomp-container-div1'>
          <span className='uploadexcel-close' onClick={close}>&times;</span>
          <div className='popup-container-sub'>
            <div className='add-component-heading'>
              <p>Add Leave Component</p>
            </div>
            <div className='addleave-strightline'></div>
            <div className='sal-comp-form-div'>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text'> Leave Type <span className='sal-comp-input-important'> * </span> </span>
                <input type='text' onChange={handleInputChange} name='leave_type' className='sal-comp-input' />
                {errors.leave_type && (<div className='error-message'>{errors.leave_type}</div>)}
              </div>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text'> Leave Name <span className='sal-comp-input-important'> * </span> </span>
                <input type='text' onChange={handleInputChange} name='leave_name' className='sal-comp-input' />
                {errors.leave_name && (<div className='error-message'>{errors.leave_name}</div>)}
              </div>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text' > Leave Deduction Type <span className='sal-comp-input-important'> * </span> </span>

                <select className='sal-comp-select-box' onChange={handleInputChange} name='leave_deduct'  >
                  <option value="">select Deduction Type</option>
                  <option value="Fullday">Full Day</option>
                  <option value="Halfday">Half Day</option>
                </select>
                {errors.leave_deduct && (<div className='error-message'>{errors.leave_deduct}</div>)}
              </div>
              <div className='sal-comp-input-div'>
                <span className='sal-comp-input-div-span-text' > Salary Type <span className='sal-comp-input-important'> * </span> </span>

                <select className='sal-comp-select-box' onChange={handleInputChange} name='earning_deduction' >
                  <option value="">select Salary Type</option>
                  <option value="earnings">Earning</option>
                  <option value="deduction">Deduction</option>
                </select>
                {errors.earning_deduction && (<div className='error-message'>{errors.earning_deduction}</div>)}
              </div>
              {errors.response_error && (<div className='error-message'>{errors.response_error}</div>)}
              <div className='sal-comp-button-div'>
                <button onClick={handleSubmit} className='popup-button-cls' disabled={loading}>{loading ? <span className='spinner'></span> : "Submit"}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addleavecomponent