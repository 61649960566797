import React, { useState } from 'react';
import './companyinfo.css';
import uploadlogo from '../../../Images/Register/logoupload';
import {RegisterCompany} from '../../../Config/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import { isAuthenticated } from '../../../Services/Authentication';
import companimg from '../../../Images/Company/Company_det';
const Companyinfo = () => {
  const [formData, setFormData] = useState({
    company_name: '',
    address_line1: '',
    company_phone_num: '',
    city: '',
    company_email: '',
    state: '',
    address_line2: '',
    pincode: '',
    company_logo:'',
    branch_type:'head',
    user:1
  });  
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") { 
      const file = event.target.files[0];
      if(file)
        {
          console.log(file);
          setSelectedImage(URL.createObjectURL(file));
          setFormData((state) => ({
            ...state,
            [name]: file,
          }));
        }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleSubmit = async () => {
    const newErrors = {};
    if (formData.company_name.trim() === "") {
      newErrors.company_name = "Comapny Name is required";
    } 
    else if (!/^[a-zA-Z\s]*$/.test(formData.company_name)) {
      newErrors.company_name =
        "Company Name must contain only alphabetic characters and spaces";
    }    
    if (formData.company_phone_num.trim() === "") {
      newErrors.company_phone_num = "Company Phone is required";
    } 
    else if (!/^\d+$/.test(formData.company_phone_num)) {
      newErrors.company_phone_num = "Company Phone must contain only digits";
    } 
    else if (formData.company_phone_num.length < 10 || formData.company_phone_num.length > 13) {
      newErrors.company_phone_num = "Company Phone must be between 10 to 13 digits";
    }    
    if (formData.company_email.trim() === '') {
      newErrors.company_email = 'Company Email is required';
    } 
    else if (!/^\S+@\S+\.\S+$/.test(formData.company_email)) {
      newErrors.company_email = 'Invalid email format';
    }    
    if (formData.address_line1.trim() === '') {
      newErrors.address_line1 = 'Address Line1 is required';
    }
    
    if (formData.address_line2.trim() === '') {
      newErrors.address_line2 = 'Address Line2 is required';
    }    
    if (formData.city.trim() === '') {
      newErrors.city = 'City is required';
    }    
    if (formData.state.trim() === '') {
      newErrors.state = 'State is required';
    }
    if (formData.pincode.trim() === '') {
      newErrors.pincode = 'Pincode is required';
    }    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      console.log('Form submitted successfully');
      try{
        setLoading(true);
        const response = await RegisterCompany(formData);
          console.log("company details",response);
          if(response)
            {
              toast.success('Company Details Process successful!', {
              position: "top-center",
              autoClose: 2000,
              closeButton: false,
              className:'custom_toast'
              });
            }
           }
      catch(error)
      {
        toast.error('Process Failed Please try again.', {
          position: "top-center",  // Use a string directly for the position
          autoClose: 2000,
          closeButton: false,
        });
      } 
      finally{
        setLoading(false);
      }
    }
  };  

  if(isAuthenticated)
  {
   // return <Navigate to="/dashboard" />
  }

  return (
    <div className='registeration-container'>
      <div className='companyinfo-container-sub'>
        <div className='registeration-content'>
        
          <div className='register-split-1'>
          <div className='companyinfo-content-sub'>
            <div className='companyinfo-main'>
              {/* <div className='registeration-logo'>
                <img className="registeration-logo-img" src={logo.logo_img} alt="Login" />
              </div> */}
                            <p className='heading-comp'>COMPANY DETAILS</p>

              <div>
              <div className='companyinfo-form-fields'>
               
                <label htmlFor="company-logo" className='companyinfo-label-view'>Company Logo</label>
                <div className='companyinfo-imgupload'>
                  <div className='companyinfo-imgupload-sub' onClick={handleInputChange}>
                    <div>
                      {selectedImage ? (
                        <img
                          id="preview-image"
                          name='company-logo'
                          className='companyinfo-img-field-view'
                          src={selectedImage}
                          alt="Uploaded"
                        />
                      ) : (
                        <img
                          id="preview-image"
                          className='companyinfo-img-field'
                          src={uploadlogo.upload_logo}
                          alt="Uploaded"
                        />
                      )}
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      name='company_logo'
                      onChange={handleInputChange}
                     style={{width:"100%",height:"100%",position:"absolute", opacity:"-0.5", fontSize:"1px"}}
                    />
                  </div>
                  {errors.company_logo && <span className='companyinfo-error'>{errors.company_logo}</span>}
                </div>
                <br/>
                <div className='companyinfo-input-row'>
                  <div className='companyinfo-input-group'>
                    <label htmlFor='companyName'>Company Name</label>
                    <input
                      id='companyName'
                      name='company_name'
                      type='text'
                      className='companyinfo-input-field'
                      onChange={handleInputChange}
                    />
                    {errors.company_name && <span className='companyinfo-error'>{errors.company_name}</span>}
                  </div>
                
                   <div className='companyinfo-input-group'>
                    <label htmlFor='companyPhone'>Company Phone</label>
                    <input
                      id='companyPhone'
                      name='company_phone_num'
                      type='text'
                      className='companyinfo-input-field'
                      onChange={handleInputChange}
                    />
                    {errors.company_phone_num && <span className='companyinfo-error'>{errors.company_phone_num}</span>}
                  </div>
                </div>
                <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                    <label htmlFor='companyEmail'>Company Email</label>
                    <input
                      id='companyEmail'
                      name='company_email'
                      type='text'
                      className='companyinfo-input-field'
                      onChange={handleInputChange}
                    />
                    {errors.company_email && <span className='companyinfo-error'>{errors.company_email}</span>}
                  </div>
               
                </div>
                <div className='companyinfo-input-row'>
                <div className='companyinfo-input-group'>
                    <label htmlFor='addressLine1'>Address Line 1</label>
                    <input
                      id='addressLine1'
                      name='address_line1'
                      type='text'
                      className='companyinfo-input-field'
                      onChange={handleInputChange}
                    />
                    {errors.address_line1 && <span className='companyinfo-error'>{errors.address_line1}</span>}
                  </div>
                    <div className='companyinfo-input-group'>
                    <label htmlFor='addressLine2'>Address Line 2</label>
                    <input
                      id='addressLine2'
                      name='address_line2'
                      type='text'
                      className='companyinfo-input-field'
                      onChange={handleInputChange}
                    />
                    {errors.address_line2 && <span className='companyinfo-error'>{errors.address_line2}</span>}
                  </div>
                 
                </div>
                <div className='companyinfo-input-row'>
                  <div className='companyinfo-input-group'>
                    <label htmlFor='city'>City</label>
                    <input
                      id='city'
                      name='city'
                      type='text'
                      className='companyinfo-input-field'
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                    {errors.city && <span className='companyinfo-error'>{errors.city}</span>}
                  </div>
                   <div className='companyinfo-input-group'>
                    <label htmlFor='state'>State</label>
                    <input
                      id='state'
                      name='state'
                      type='text'
                      className='companyinfo-input-field'
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                    {errors.state && <span className='companyinfo-error'>{errors.state}</span>}
                  </div>
                  <div className='companyinfo-input-group'>
                    <label htmlFor='pincode'>Pincode</label>
                    <input
                      id='pincode'
                      name='pincode'
                      type='text'
                      className='companyinfo-input-field'
                      value={formData.pincode}
                      onChange={handleInputChange}
                    />
                    {errors.pincode && <span className='companyinfo-error'>{errors.pincode}</span>}
                  </div>
                </div>
                
                <div className='companyinfo-div-btn'>
                  <button type='button' onClick={handleSubmit} > { loading ? 'loader' :  'Submit' }</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='register-split'>
                          <p className='para-reg'>Get started on your journey with us! 
                          :)</p>

            <div className='register-img-view'>
              <div className='register-img-view-sub'>

              <img src={companimg.company_img} alt='img'/>

              </div>
            </div>
          </div>
    </div>
    </div>
    </div>
  );
};export default Companyinfo;
