import React, { useState } from 'react';

import './Uploadexcel.css';
import { addExcelToDatabaseApi } from '../../../Config/api';
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
const Uploadexcel = ({show, close}) => {
    const [file, setFile] = useState(null);
    const [dragging, setDragging] = useState(false);
    const user_details = getUserDetails();
    const company_details = getCompanyDetails();
    if (!show) return null

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile ) {
        setFile(selectedFile);
      } else {
        setFile(null);
      }
    };
  
    const handleDrop = (event) => {
      event.preventDefault();
      setDragging(false);
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile ) {
        setFile(selectedFile);
      } else {
      }
    };
  
    const handleUpload = async () => {
      if (file) {
        const data = {
          "attendance_file" : file,
          "register"    : user_details.id,
          "company"     : company_details.company_id,        
        }
        try {
          const response = await addExcelToDatabaseApi(data);
          console.log(response);
        close(); 
        }
        catch(error)
        {

        }
      } else {
      }
    };
  return (
    <div className='uploadexcel-section'>
    <div className='uploadexcel-container'>
        
        <div className='uploadexcel-container-sub'>
            <div className='uploadexcel-content'>
          
           <span className='uploadexcel-close' onClick={close}>&times;</span>
           <p>Upload Excel</p>
        <div
          className={`uploadexcel-upload-area ${dragging ? 'dragging' : ''}`}
          onDragOver={(e) => {
            e.preventDefault();
            setDragging(true);
          }}
          onDragLeave={() => setDragging(false)}
          onDrop={handleDrop}
        >
          {file ? <p>Selected file: {file.name}</p> : <p>Drag and drop your Excel file here, or click to select</p>}
          <input
            type="file"
            accept=".xlsx"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload" className="uploadexcel-upload-button">Browse Files</label>
        </div>
        <button className='uploadexcel-upload-btn' onClick={handleUpload} disabled={!file}>
          Upload
        </button>
       
                </div>
      
        </div>
        </div>
        </div>
  )
}

export default Uploadexcel