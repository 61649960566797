import React, {useEffect,useState} from 'react';
import './Index.css';
import './Dashboard.css';
import Header from '../Header/Header';
import Siderbar from '../Sidebar/Sidebar';
import CalendarDashboard from './CalendarDashboard';
import DashboardImages from '../../Images/DashboardImages/DashboardImages';
import { FetchLeavecompApi, StaffAllDetailsApi, Viewdashboardcount, viewSalaryComponentApi } from '../../Config/api';
import {  getStaffDetails, getUserDetails, storeStaffDetails } from '../../Services/Storage';
import { Store } from '../../Store';
import { setLeaveComponent as setLeaveComponentRedux } from '../../Slice/leaveComponentSlice';
import { setSalaryComponent as setSalaryComponentRedux } from '../../Slice/salaryComponentSlice';
const Dashboard = () => {
  const user_details = getUserDetails();
  const [userName, setUserName] = useState("User");
  const[dashboardct, setdashboardct]=useState([]);
  useEffect(() => {
    const fetchLeaveComponent = async () => {
      try {
        const response = await FetchLeavecompApi(`?register_id=${user_details.id}`);
        Store.dispatch(setLeaveComponentRedux(response.data.get_leave_component));
      } catch (error) {
        console.error("Error fetching leave list:", error);
      }
    };
    const fetchsalaryComponent = async () =>{
      try {
        const response = await viewSalaryComponentApi(`?register_id=${user_details.id}`);
        Store.dispatch(setSalaryComponentRedux(response.data.get_salary_component_earning));
      } catch (error) {
        console.error("Error fetching leave list:", error);
      }
    }
    const fetchStaffAllDetails = async()=>{
      const staff_details = getStaffDetails();

      if(user_details.role === 'staff')
      {
        console.log("trtrtrtrt ", user_details.role);
        try {
          const response = await StaffAllDetailsApi(`?register_id=${user_details.id}&admin_id=${staff_details.register_details.user_id}`);
          storeStaffDetails(response.data.staff_all_details);
          setUserName(response.data.staff_all_details.register_details[0].name);
          console.log("response staff detsila" , response);
        } catch (error) {
          console.error("Error fetching leave list:", error);
        }
      }
    }

    const fetchdashboardcount = async() =>{
      console.log(user_details.role)
      if(user_details.role === 'Company')
        {
        try{
          const response = await Viewdashboardcount(`?register_id=${user_details.id}` )
          setdashboardct(response.data)
          console.log(response.data.all_count ,"dhjdh")
        }catch(error){
          console.error("Error fetching leave list:", error);
      }
    }
    }
    fetchdashboardcount();
    fetchLeaveComponent();
    fetchsalaryComponent();
    fetchStaffAllDetails();
}, [user_details.id ,user_details.role]);
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
      <div className='index-content'>
      <Siderbar />
      <div className='index-content-sub'>
        <div className='index-main'>
          <div className='dashboard-div-1'>
            <div className='dashboard-div1-child1'>
              {user_details.role ==='Company'
              ?(
              <div>
                <h2>Welcome {user_details.name}</h2>
              </div>
              )
              :(
                <div>
                <h2>Welcome { userName }</h2>
              </div> 
              )
              }
            </div>
            {
               user_details.role === 'Company'
               ?
               (
                <div className='dashboard-div1-child2'>
                   <div className='dashboard-first-div'>
                  <span className="span">
                    <img src={DashboardImages.usersall} className='dashboard-svg' alt='User Icon' />
                    <span className='dashboard-first-div-span1'> 
                      <b>Total Users</b>
                      <p>{dashboardct.all_count}</p>
                    </span>
                  </span>
                </div>
                <div className='dashboard-first-div'>
                  <span className="span">
                    <img src={DashboardImages.activeusers} className='dashboard-svg' alt='User Icon' />
                    <span className='dashboard-first-div-span1'>
                      <b>Active Users</b>
                      <p>{dashboardct.deactive_count}</p>
                    </span>
                  </span>
                </div>
                <div className='dashboard-first-div'>
                  <span className="span">
                    <img src={DashboardImages.deactiveusers} className='dashboard-svg' alt='User Icon' />
                    <span className='dashboard-first-div-span1'>
                      <b>Deactive Users</b>
                      <p>{dashboardct.active_count}</p>
                    </span>
                  </span>
                </div>
                <div className='dashboard-first-div'>
                  <span className="span">
                    <img src={DashboardImages.leave} className='dashboard-svg' alt='User Icon' />
                    <span className='dashboard-first-div-span1'>
                      <b>Leave Request</b>
                      <p>{dashboardct.leave_count}</p>
                    </span>
                  </span>
                </div>
               
              </div>
               ):(
                <div className='dashboard-div1-child2'>
                <div className='dashboard-first-div'>
               <span className="span">
                 <img src={DashboardImages.usersall} className='dashboard-svg' alt='User Icon' />
                 <span className='dashboard-first-div-span1'> 
                   <b>Total Users</b>
                   <p>8</p>
                 </span>
               </span>
             </div>
             <div className='dashboard-first-div'>
               <span className="span">
                 <img src={DashboardImages.activeusers} className='dashboard-svg' alt='User Icon' />
                 <span className='dashboard-first-div-span1'>
                   <b>Active Users</b>
                   <p>8</p>
                 </span>
               </span>
             </div>
             <div className='dashboard-first-div'>
               <span className="span">
                 <img src={DashboardImages.deactiveusers} className='dashboard-svg' alt='User Icon' />
                 <span className='dashboard-first-div-span1'>
                   <b>Deactive Users</b>
                   <p>8</p>
                 </span>
               </span>
             </div>
             <div className='dashboard-first-div'>
               <span className="span">
                 <img src={DashboardImages.leave} className='dashboard-svg' alt='User Icon' />
                 <span className='dashboard-first-div-span1'>
                   <b>Leave Request</b>
                   <p>8</p>
                 </span>
               </span>
             </div>
            
           </div>
               )
            }
          
            <div className='calendar-section-main'>
              <div className='calendar-section'>
                <CalendarDashboard />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  )
};
export default Dashboard