import React, { useState } from 'react'
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage'
import { Adddepartcomp } from '../../../Config/api';
import { toast } from 'react-toastify';
import { addDesignation } from '../../../Slice/designationSlice';
import { Store } from '../../../Store';

const Adddesignation = ({ show, close, sendDataToParent }) => {
  const userDetails = getUserDetails();
  const company_details = getCompanyDetails();
  const [errors, setErrors] = useState([]);
  const [loader, setloader] = useState((false));

  const [designationadd, setdesignation] = useState({
    designation: '',
    user: userDetails.id,
    company: company_details.company_id,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setdesignation((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handlesubmit = async () => {
    const fieldData = [
      {
        name: "designation",
        type: "input",
        err: "Designation Name",
        conditions: [
          (value) => typeof value === "string",
          (value) => value.trim() !== "",
          (value) => /^[a-zA-Z\s]+$/.test(value),
        ]
      }
    ]
    const fieldOrder = [
      "designation"
    ]
    const newErrors = {};
    for (const fieldName of fieldOrder) {
      const fieldInfo = fieldData.find((field) => field.name === fieldName);
      if (!fieldInfo) {
        continue;
      }
      const fieldType = fieldInfo.type;
      const fieldLabel = fieldInfo.err;
      const value = designationadd[fieldName];
      const isFieldValid = fieldInfo.conditions.every((condition) => typeof condition === "function" ?
        condition
          (value) : condition
      );
      if (!isFieldValid) {
        if (value.trim() === "") {
          newErrors[fieldName] = `${fieldLabel} is Required`

        } else {
          newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}.`;

        }
        const inputField = document.querySelector(
          `${fieldType}[name ="${fieldName}"]`

        );
        if (!fieldType) {
          inputField.focus();
          break;
        }
      }
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setloader(true)
        const response = await Adddepartcomp(designationadd);
        if (response) {
          console.log("FDCJFGJFG", response);
          sendDataToParent(response.data.designation);
          Store.dispatch(addDesignation(response.data.designation))

          close();
          toast.success('Designation Component Added Sucessfully!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          })
          setdesignation({
            designation: '',
            user: userDetails.id,
            company: company_details.company_id,
          })
        }
      } catch (error) {
        const errorMessage =
          error.response && error.response.data && error.response.data.data
            ? error.response.data.data
            : "Add Designation Failed. Please try again.";

        setErrors((prevErrors) => ({
          ...prevErrors,
          response_error: errorMessage,
        }));

        toast.error("Add Designation Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        });
      } finally {
        setloader(false)
      }
    }
  }
  if (!show) return null
  return (
    <div className='popup-section'>
      <div className='popup-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>

        <div className='popup-container-sub'>
          <div className='addleave-heading'>
            <p>Add Designation</p>

          </div>

          <div className='addleave-strightline'></div>
          <div className='popup-content-viewing'>
            <div className='popup-inputfield'>
              <div className='popup-inputfield-sub'>
                <label> Designation<span className='registeration-mandatory'> *</span> </label>
                <input className='' onChange={handleInputChange} name='designation' type='text' />
              </div>
              {errors.designation && (<div className='error-message'>{errors.designation}</div>)}
            </div>
            {
              errors.response_error && (
                <div className='error-message-view salry-comp-err'>
                  {errors.response_error.component}
                </div>
              )
            }
            <div className='popup-button'>
              <button onClick={handlesubmit}>{loader ? <span className='spinner'></span> : "Submit"}</button>

            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Adddesignation