import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const departmentTlSlice = createSlice({
    name : 'department_tl',
    initialState,
    reducers:{
        addDepartmentTl(state, action)
        {
            state.push(action.payload)

        },
        setDepartmentTL(state, action) {
            return action.payload;
        },
        updateDepartmentTL(state,action)
        {
            const updatedComponent = action.payload;
            return state.map((component)=>
            component.id ===updatedComponent.id
            ?{...component, ...updatedComponent}
    :component
            )
        }
    }
})

export const { addDepartmentTl, setDepartmentTL,updateDepartmentTL  } = departmentTlSlice.actions;
export default departmentTlSlice.reducer;