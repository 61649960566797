import React, { useState, useEffect,useCallback } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from 'react-select';
import Uploadexcel from '../Uploadexcel/Uploadexcel';
import Calendar from '../Viewattncalender/Viewattncal';
import './Viewattendance.css';
import { CiSearch } from 'react-icons/ci';
import getMonthYearData from '../../Component/MonthYearList';
import { CompanyApi, DesignationApi } from '../../../Config/CommonApi';
import { viewAttendanceApi, viewSingleAttendanceApi } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import pageloader from '../../../Loader/pageloading/Pageloader';
import monthNames from '../../Component/Monthview';

const Viewattendance = () => {
const [currentpage, setCurrentPage] = useState(1);
const [isuploadexcel, setopenuploadexcel] = useState(false);
const [iscalander, setopencalander] = useState(false);
const [companyData, setCompanyData] = useState([]);
const [designationData, setDesignationData] = useState([]);
const [attendance, setAttendance] = useState([]);
const [singleAttendance, setSingleAttendance] = useState([]);
const [company, setCompany] = useState("");
const [designation, setDesignation] = useState("");
const [search, setSearchword] = useState("");
const [selectedStaffId, setSelectedStaffId]= useState("");
const [year, setYear] = useState(new Date().getFullYear());
const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
const user_details = getUserDetails();
const [companyFilter, setCompanyFilter] = useState([]);
const [designationfliter, setdesignationfilter] = useState([]);
const [totalLeaveCount, setTotalLeaveCount] = useState(0);
const [isLoading, setIsLoading] = useState(true); 

const per_page = 10;
const handleChangeCompany = (selecteOption) => {
setCompany(selecteOption.value);
setCompanyFilter(selecteOption)

};
const handleChangeDesignation = (selecteOption) => {
setDesignation(selecteOption.value);
    setdesignationfilter(selecteOption);

};
const handleChangeYear = (selecteOption) => {
setYear(selecteOption.value);
}
const handleChangeMonth = (selecteOption) => {
setMonth(selecteOption.value);
}
const handleInputChange = (event) =>{
const {value} = event.target;
setSearchword(value);
}
const currentPageNo = (selectedPage) => {
  setCurrentPage(selectedPage.selected + 1);
};
const openpopupclander = async(id) =>{
setSelectedStaffId(id);
try{
const response = await viewSingleAttendanceApi(`?register_id=${id}&month=${month}&year=${year}&admin_id=${user_details.id}`)
if(response)
{
setSingleAttendance(response);
setopencalander(true);
}
}
catch(error)
{

}
}
const closepopupcalander =() =>{
setopencalander(false)
}
const openuploadexcel = () =>{
setopenuploadexcel(true);
}
const closeuploadexcel =() =>{
setopenuploadexcel(false);
}
useEffect(() => {
const fetchCompanyData = async () => {
const data = await CompanyApi();
setCompanyData(data);
setCompanyFilter(data[0]);

};
const fetchDesignationData = async () => {
const data = await DesignationApi();
setDesignationData(data);
setdesignationfilter(data[0]);

};
fetchCompanyData();
fetchDesignationData();
}, []);

const { months, years } = getMonthYearData();
const keys = Object.keys(months);
console.log(keys);
const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
{
value: key,
label: months[key],
}));

const yearData = Object.keys(years).map(key => ({
value: years[key],
label: years[key],
}));

const fetchAttendanceList = useCallback(async () =>{
try{
  setIsLoading(true); 

const response = await viewAttendanceApi(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}&company=${company}&designation=${designation}&month=${month}&year=${year}`);
console.log("leave data" , response);
if(response)
{
setAttendance(response.data.get_attendance);
setTotalLeaveCount(response.data.page_context.total);

}
}
catch(error)
{

}finally{
  setIsLoading(false); 

}
},[currentpage,per_page,search,company,designation,month,year,user_details.id]);

useEffect(()=>{
fetchAttendanceList();
},[fetchAttendanceList])

// const attendance = attendanceList.get_attendance;
return (
<div className='index-container'>
<Header />
<div className='index-content-parent'>
<div className='index-content'>
<Sidebar />
<div className='index-content-sub'>
<div className='index-main'>
<div className='pages-subheader'>
<div className='pages-subheader-sub-1'>
    
            <p>Attendance Report</p>
            <span className='header-search-add-parent'>
            <div className='pages-serach-view'>
            <CiSearch className='page-search-icon'/>
            <input className='pages-serach' onChange={handleInputChange} placeholder='Search Employee ' type='search'/>
            </div> 
            <button className='pages-addempbutton' onClick={openuploadexcel} >Upload Excel</button>
        </span>
      
      </div>
      <div className='viewlpageseave-line'></div>
    </div>
    <div className='pages-filter-options'>
      <div className='pages-filter-sub'>
        <p>Filter:</p>
        <Select className='pages-dropdown1' options={companyData} value={companyFilter}
      onChange={handleChangeCompany} placeholder="Select Work Location" />
      
      <Select className='pages-dropdown' value={designationfliter} options={designationData}
      onChange={handleChangeDesignation} placeholder="Select Designation" />
      <Select className='pages-dropdown' options={yearData}
      onChange={handleChangeYear}
      value={yearData.find(option => option.value === year)}
      placeholder="Select Year" />
      <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth}  value={monthData.find(option => option.value === month)}
      placeholder="Select Month" />
      
      </div>
    </div>
    <div className='pages-maincontent'>
    {isLoading ? ( 
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
      <table className='pages-table'>
        <thead>
          <tr>
          
            <th>S.No</th>
            <th>Month / Year</th>
            <th>Emp Id</th>
            <th>Name</th>
            <th>Punched</th>
            <th>Leave</th>
            <th>Holiday</th>
            <th>View</th>
          </tr>
        </thead>
        <tbody>
  {attendance.length > 0 ? (
    attendance.map((atndnce, index) => {
      return (
        <tr key={atndnce.registration_id}>
          <td>{index + 1}</td>
          <td>{monthNames[atndnce.month]}/ {atndnce.year}</td> {/* Display month name here */}
          <td>{atndnce.employee_id}</td>
          <td>{atndnce.employee_name}</td>
          <td>{atndnce.attendance_count}</td>
          <td>{atndnce.leave_count}</td>
          <td>{atndnce.holiday_count}</td>
          <td onClick={() => openpopupclander(atndnce.registration_id)}>
            <div className='viewattn-view-button'>View</div>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={8} className="no-data-table">
        <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
      </td>
    </tr>
  )}
</tbody>

      </table>
         )}
      <div style={{ float: "right" }} id="pagination_link"></div>
                <div style={{ padding: "20px" }}>
                  {totalLeaveCount > per_page ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totalLeaveCount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}  
                </div>
    </div>
</div>
</div>
</div>
</div>
<Uploadexcel
show ={isuploadexcel}
close ={closeuploadexcel}
/>
{singleAttendance && (
<Calendar
show={iscalander}
close={closepopupcalander}
responseData={singleAttendance}
selMonth = {month}
selYear = {year}
staffId = {selectedStaffId}
/>
)}

</div>

)
}

export default Viewattendance