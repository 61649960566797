import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const leaveSlice = createSlice({
    name : 'leave',
    initialState,
    reducers:{
        addLeave(state, action)
        {
           // console.log("return added leave ", action.payload);
            state.push(action.payload); // Add the new leave data to the state

        },
        setLeave(state, action) {
            //console.log("return leave data from redux", action.payload);
            return action.payload;
        },

        updateLeave(state, action) {
            const leavesToUpdate = action.payload; // Payload is an array of leaves
      console.log('Updating leaves:', leavesToUpdate); // Log what’s being updated

      // Create a new state array by mapping over the current state
      const updatedState = state.map(leave => {
        const updatedLeave = leavesToUpdate.find(l => l.id === leave.id);
        if (updatedLeave) {
          console.log('Updating leave with ID:', leave.id, 'Current State:', leave); // Log before updating
          return {
            ...leave,
            ...updatedLeave, // Merge properties of updatedLeave into leave
          };
        }
        return leave; // Return the leave as is if not updated
      });

      console.log('State after updates:', updatedState); // Log state after updates
      return updatedState; // Return the updated state
        }
    }
})

export const { addLeave, setLeave, updateLeave  } = leaveSlice.actions;
export default leaveSlice.reducer;