import React, { useRef ,useState } from 'react';
import html3pdf from 'html3pdf'; // Changed to html3pdf
import './Viewslippopup.css';

const Viewslippopup = ({ show, close, salarydata }) => {
  const payslipRef = useRef();
  console.log("salary data for popup " , salarydata);
  const [isDownloading, setIsDownloading] = useState(false); // State to manage download button visibility

  if (!show) return null;
if(!salarydata) return null;
  const employee = salarydata;
  console.log(employee ,"isssssca")



  const downloadPDF = () => {
    setIsDownloading(true); // Hide the button when the download starts

    const element = payslipRef.current;
    const options = {
      margin: 0.5,
      filename: `payslip_${employee.work_details.emp_id}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };
    
    html3pdf().set(options).from(element).save().then(() => setIsDownloading(false));
    ;
  };


  return (
    <div className='viewslippopup-section'>
      <div className='viewslippopup-container'>
        <span className='uploadexcel-close' onClick={close}>&times;</span>
        <div className="payslip" id="payslip" ref={payslipRef}>
          {/* Your existing payslip content */}
          <div className="payslip-header">
            <h3>{employee.company_details.company_name}</h3>
            <p> {employee.company_details.address_line1} ,{employee.company_details.address_line2}  </p>
            <p>{employee.company_details.city} , {employee.company_details.state} , {employee.company_details.pincode}</p>
          </div>
          <div className='payslip-employee-container'>
            <div className="payslip-employee">
              <p><strong>Employee Id:</strong> {employee.work_details.emp_id}</p>
              <p><strong>Employee Name:</strong> {employee.register_details.name}</p>
              <p><strong>Department:</strong> {employee.department_name}</p>
              <p><strong>Designation:</strong> {employee.designation_name}</p>
            </div>
            <div className="payslip-employee">
              <p><strong>Bank Name:</strong> {employee.payment_details.bank_name || ""}</p>
              <p><strong>Account Number:</strong> {employee.payment_details.account_num || ""}</p>
              <p><strong>UAN:</strong> {employee.payment_details.uan_num || ""}</p>
              <p><strong>Month & Year:</strong> {employee.salary_slip.month} / {employee.salary_slip.year}</p>
            </div>
          </div>
          <div className="payslip-details">
            <div className='payslip-earnings'>
              <table>
                <tr>
                  <th colSpan={2}>Earnings</th>
                  <th colSpan={2}>Deductions</th>
                </tr>
                <tbody>
                  <tr>
                    <td>Description</td>
                    <td>Amount</td>
                    <td>Description</td>
                    <td>Amount</td>
                  </tr>
                  {
                    (() => {
                      const earnings = employee.salary_slip.salary_compo_list.filter(item => item.earning_deduction === 'earning');
                      const deductions = employee.salary_slip.salary_compo_list.filter(item => item.earning_deduction === 'deduction');
                      const maxLength = Math.max(earnings.length, deductions.length);

                      return Array.from({ length: maxLength }).map((_, index) => (
                        <tr key={index}>
                          <td>{earnings[index]?.component_name || ''}</td>
                          <td>{earnings[index]?.monthly || ''}</td>
                          <td>{deductions[index]?.component_name || ''}</td>
                          <td>{deductions[index]?.monthly || ''}</td>
                        </tr>
                      ));
                    })()
                  }
                  <tr className="total-row">
                    <td><strong>Total Earnings</strong></td>
                    <td><strong>{employee.salary_slip.monthly_salary.toFixed(2)}</strong></td>
                    <td><strong>Total Deductions</strong></td>
                    <td><strong>{employee.salary_slip.deduct_salary.toFixed(2)}</strong></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="payslip-summary">
            <h4>Summary</h4>
            <p><strong>Net Pay:</strong> ₹{employee.salary_slip.earning_salary.toFixed(2)}</p>
          </div>
          <div className="payslip-footer">
          {!isDownloading && ( // Conditionally render the button based on `isDownloading` state
              <button className='payslip-download-button' onClick={downloadPDF}>Download</button>
            )}          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewslippopup;