import React, { useState, useEffect } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from 'react-select';
import './Viewpayroll.css'
import getMonthYearData from '../../Component/MonthYearList';
import {  viewSingleStaffSalaryApi, viewStaffSalarySlipApi } from '../../../Config/api';
import { getStaffDetails, getUserDetails } from '../../../Services/Storage';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import Viewslippopup from '../viewslippopup/Viewslippopup';
import monthNames from '../../Component/Monthview';

const ViewStaffPayroll = () => {
const staff_details = getStaffDetails();

const [currentpage, setCurrentPage] = useState(1);
const per_page = 10;
// const [search, setSearchword] = useState("");
const [year, setYear] = useState(new Date().getFullYear());
const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
const [employees, setEmployees] = useState([]);
const user_details = getUserDetails();
const [isLoading, setIsLoading] = useState(true); 
const [totalLeaveCount, setTotalLeaveCount] = useState(0);
const [ispopviewslip ,showpopupslip] = useState(false);
const [slipData, setslipData] = useState([]);

const handleChangeYear = (selecteOption) => {
setYear(selecteOption.value);
}
const handleChangeMonth = (selecteOption) => {
setMonth(selecteOption.value);
}
// const handleInputChange = (event) =>{
// const {value} = event.target;
// setSearchword(value);
// }




const { months, years } = getMonthYearData();
const keys = Object.keys(months);
console.log(keys);
const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
{
value: key,
label: months[key],
}));

const yearData = Object.keys(years).map(key => ({
value: years[key],
label: years[key],
}));

useEffect(() => {
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');  // getMonth returns 0 for January, so add 1
  const currentYear = new Date().getFullYear().toString();     // Get the full year
  setMonth(currentMonth);
  setYear(currentYear);
}, []); // Empty dependency array ensures this runs once on mount



useEffect(() => {
    const opensalaryslip = async () => {
      try {
        setIsLoading(true);
        const response = await viewSingleStaffSalaryApi(`${user_details.id}/?admin_id=${staff_details.register_details.user_id}`);
        if (response) {
          setEmployees(response.data.salary_report);
          setTotalLeaveCount(response.data.page_context.total);
        }
      } catch (error) {
        // Handle error if necessary
      } finally {
        setIsLoading(false);
      }
    };
  
    opensalaryslip();
  }, [currentpage, per_page, month, year, user_details.id]);
  





const currentPageNo = (selectedPage) => {
  setCurrentPage(selectedPage.selected + 1);
};

const openpopslip = async(id, month, year) =>{
    try{
        const response = await viewStaffSalarySlipApi(
            `?register=${user_details.id}&month=${month}&year=${year}}&admin_id=${staff_details.register_details.user_id}`
          );
        if(response.data)
        {
            console.log("return sucerss",response.data.salary_report[0]);
            setslipData(response.data.salary_report[0])
            showpopupslip(true);
        }
    }
    catch(error)
    {

    }
}
const closepopupslip =() =>{
    showpopupslip(false);
}

  return (
    <>
<div className='index-container'>
<Header />
<div className='index-content-parent'>
<div className='index-content'>
<Sidebar />
<div className='index-content-sub'>
<div className='index-main'>
<div className='pages-subheader'>

<div className='pages-subheader-sub-1'>
<p>Payroll</p>
<span className='header-search-add-parent'>
{/* <div className='pages-serach-view'>
<CiSearch className='page-search-icon'/>
<input className='pages-serach' onChange={handleInputChange} placeholder='Search Payroll' type='search'/>
</div>  */}
</span>
</div>


</div>
<div className='pages-line'></div>
<div className='pages-filter-options'>
<div className='pages-filter-sub'>
<p>Filter:</p>

<Select className='pages-dropdown' options={yearData} value={yearData.find(option => option.value === year)}

onChange={handleChangeYear}
placeholder="Select Year" />
<Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth} value={monthData.find(option => option.value === month)} placeholder="Select Month" />

</div>
</div>
<div className='pages-maincontent'>
{isLoading ? ( 
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                ) : (
<table className='pages-table'>
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Month/Year</th>
                                            <th>Actual Salary</th>
                                            <th>Deduction</th>
                                            <th>Earning</th>
                                            <th>Slip</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employees.map((employee, index) => (
                                          
                                            <tr key={employee.id}>
                                                
                                                <td> {index+1} </td>
                                                <th>{monthNames[employee.month]} / {employee.year}</th>
                                                <td>{employee.monthly_salary}</td>
                                                <td>{employee.deduct.toFixed(2)}</td>
                                                <td>{employee.earning.toFixed(2)}</td>
                                                <td onClick={()=>openpopslip(employee.register_id, employee.month , employee.year)}>
                                                <div className='viewattn-view-button'>Slip</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
 )}
     <div style={{ float: "right" }} id="pagination_link"></div>
                <div style={{ padding: "20px" }}>
                  {totalLeaveCount > per_page ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totalLeaveCount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}  
                </div>
</div>
</div>
</div>
</div>
</div>
</div>
<Viewslippopup
            show ={ispopviewslip}
            close ={closepopupslip}
             salarydata = {slipData}
            />
</>
  )
}

export default ViewStaffPayroll