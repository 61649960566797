const getMonthYearData = () => {
    const months = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    };
  
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const endYear = currentYear + 2;
  
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
  
    return { months, years };
  };
  
export default getMonthYearData;  