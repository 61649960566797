import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const departmentHeadSlice = createSlice({
    name : 'department_head',
    initialState,
    reducers:{
        addDepartmentHead(state, action)
        {
            state.push(action.payload)

        },
        setDepartmentHead(state, action) {
            console.log("department head resux  ", action.payload);
            return action.payload;
        },
        updatedepartmenthdcomp(state,action){
            const updatedComponent = action.payload;
            return state.map((component)=>
            component.id ===updatedComponent.id
            ?{...component, ...updatedComponent}
    :component
            )
        }
    }
})

export const { addDepartmentHead, setDepartmentHead,updatedepartmenthdcomp } = departmentHeadSlice.actions;
export default departmentHeadSlice.reducer;