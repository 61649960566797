import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './CalendarDashboard.css';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { AddNotesApi } from '../../Config/api';
import { getCompanyDetails, getUserDetails } from '../../Services/Storage';

const CalendarDashboard = () => {
    const [date, setDate] = useState(new Date());
    const [notes, setNotes] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);
    const [noteInput, setNoteInput] = useState('');
    const [isNoteSectionVisible, setIsNoteSectionVisible] = useState(false);
    const userDetails = getUserDetails();
    const companyDetails = getCompanyDetails();
    const handleDateChange = (date) => {
        setDate(date);
        setSelectedDate(date);
        setIsNoteSectionVisible(true); // Show the note section when a date is selected
    };

    const handleAddNote = async () => {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        setNotes({
            ...notes,
            [formattedDate]: [...(notes[formattedDate] || []), noteInput],
        });
        const data = {
            "register"  : userDetails.id,
            "date"      : formattedDate,
            "notes"     : noteInput,
            "company"   : companyDetails.company_id,
            "user"      : userDetails.user,
        }
        try{
            const response = await AddNotesApi(data)
            if(response)
            {
                
            }
        }
        catch(error)
        {

        }
        setNoteInput('');
        setIsNoteSectionVisible(false);
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        const formattedDate = date.toISOString().split('T')[0];
        setNoteInput(notes[formattedDate] ? notes[formattedDate].join('\n') : '');
        setIsNoteSectionVisible(true); // Show the note section when a date is clicked
    };

    const titleContent = ({ date, view }) => {
        const formattedDate = date.toISOString().split('T')[0];
        if (view === 'month' && notes[formattedDate]) {
            return (
                <div className="note-indicator">
                    {/* <FaStickyNote className="note-icon" /> */}
                    <div className="notes-list">
                        {notes[formattedDate].map((note, index) => (
                            <div key={index} className="note">
                                {note}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    };

    const handleCloseNoteSection = () => {
        setIsNoteSectionVisible(false); // Hide the note section
    };

    return (
        <div className="calendar-container">
            <Calendar
                onChange={handleDateChange}
                value={date}
                onClickDay={handleDateClick}
                titleContent={titleContent}
            />
            {isNoteSectionVisible && (
                <div className='note-div'>
                    <div className="note-section-div1">
                        <div className="note-section">
                            <button className="close-button" onClick={handleCloseNoteSection}>
                                <FaTimes />
                            </button>
                            <h3>Notes for {selectedDate.toDateString()}</h3>
                            <textarea
                                value={noteInput}
                                onChange={(e) => setNoteInput(e.target.value)}
                            />
                            <button onClick={handleAddNote}>
                                <FaPlus /> Add Note
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CalendarDashboard;
