import React from 'react';

const Confirmation = ({ isOpen, onClose, onConfirm, message }) =>{
    if (!isOpen) return null;

    return(
    <div className="modal-overlay">
    <div className="modal-content">
      <p>{message}</p>
      <div className="modal-buttons">
        <button onClick={onConfirm} className="confirm-button">Yes</button>
        <button onClick={onClose} className="cancel-button">No</button>
      </div>
    </div>
  </div>
);
}
export default Confirmation