import axios from 'axios';
import { getUserToken } from '../Services/Storage';
import apiUrl from './Urls';

// axios.defaults.baseURL = "http://127.0.0.1:8000/";
// const COMPANY_URL = "api/company_details/";

// const api_backend = {
//   BACKEND_API: "http://127.0.0.1:8000/"
// }

axios.defaults.baseURL = "https://staffin.seataxi.in/";
const COMPANY_URL = "api/company_details/";

const api_backend = {
  BACKEND_API: "https://staffin.seataxi.in/"
}

export default api_backend;

export const Registration = async (data) => {
  // axios.post(REGISTER_URL, {data:user})
  try {
    const response = await axios.post(apiUrl.REGISTER_URL, data);
    return response.data; // Assuming the response contains relevant data
  } catch (error) {
    throw error;
  }
}

export const SignInApi = async (data) => {
  try {
    const response = await axios.post(apiUrl.LOGIN_URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const RegisterCompany = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(COMPANY_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const ViewEmployeeApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_EMPLOYEE}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const ViewEditEmployeeApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_EDIT_EMPLOYEE}${userId}/`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const UpdateEmployeeBasicApi = async (userId, updatedDetails) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_STAFF}/${userId}/`, updatedDetails, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        'Content-Type': "multipart/form-data", // Set appropriate content type
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const UpdateEmployeePersonalApi = async (userId, updatedDetails) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_REGISTER}/${userId}/`, updatedDetails, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        'Content-Type': 'application/json', // Set appropriate content type
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const UpdateEmployeePaymentApi = async (userId, updatedDetails) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_PAYMENT}/${userId}/`, updatedDetails, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        'Content-Type': 'application/json', // Set appropriate content type
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const EmployeePersonalApi = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.REGISTER_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

//employee basic details insert

export const EmployeeBasicApi = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.STAFF_BASIC_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  catch (error) {
    throw error;
  }
}
export const EmployeeSalaryApi = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.STAFF_SALARY_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "application/json",
      }
    });
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const EmployeePaymentApi = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.STAFF_PAYMENT_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "application/json",
      }
    });
    return response.data;
  }
  catch (error) {
    throw error;
  }
}


// View leave

export const ViewLeaveApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_LEAVE}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}


//Add leave Employee Fetch 

export const FetchEmployee = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.FETCH_EMPLOYEE}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    })
    return response;
  }
  catch (error) {
    throw error;
  }
}

//ADD LEAVE

export const AddLeave = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_LEAVE, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
  catch (error) {
    throw error;
  }
}
//Staff leave

export const Addstaffleav = async(data)=>{
  var datatoken = getUserToken();
  try{
    const response = await axios.post(apiUrl.ADD_STAFF_LEAVE,data,{
      headers :{
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;

  }catch(error){
    throw error;

  }
}

//Fetch Leave component

export const FetchLeavecompApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.FETCH_LEAVE_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

//update the status for approved pending rejected

export const UpdateStatusLeave = async (data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.put(apiUrl.UPDATE_LEAVE_STATUS, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        'Content-Type': 'application/json', // Set appropriate content type
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const viewSalaryComponentApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_SALARY_COMPONENT_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}
//view salary component deduction

export const viewSalaryComponentDeductionApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_SALARY_COMPONENT_DEDUCTION_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const addExcelToDatabaseApi = async (data) => {
  var datataken = getUserToken();
  try {
    const response = await axios.post(apiUrl.EXCEL_TO_DATABASE_URL, data, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// View Attendance

export const viewAttendanceApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_ATTENDANCE_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

//view single attendance
export const viewSingleAttendanceApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_SINGLE_ATTENDANCE_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// Staff Salary payroll

export const viewStaffSalaryApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.SALARY_STAFF_VIEW_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// Single Staff Salary Slip
export const viewSingleStaffSalaryApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.SINGLE_STAFF_SALARY_SLIP_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        'Content-Type': 'application/json',
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// Staff Salary Slip Url
export const viewStaffSalarySlipApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.STAFF_SALARY_SLIP_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// Salary calculation
export const AddsalaryCalculationApi = async (data) => {
  var datataken = getUserToken();
  try {
    const response = await axios.post(apiUrl.SALARY_CALCULATION_URL, data, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        'Content-Type': 'application/json',
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// salary Approved

export const salaryStatusApproveApi = async (data) => {
  var datataken = getUserToken();
  try {
    const response = await axios.put(apiUrl.SALARY_STATUS_APPROVED, data, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        'Content-Type': 'application/json',
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}
// Add Salary Component

export const AddSalaryComponentApi = async (data) => {
  var datataken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_SALARY_COMPONENT, data, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// delete Salary component

export const deleteSalaryComponentApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.delete(`${apiUrl.DELETE_SALARY_COMPONENT_URL}${userId}/`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// Update Salary Component 


export const UpdateSalarydetailsApi = async (userId, updatedDetails) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_SALARY_DETAIL_URL}${userId}/`, updatedDetails, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        'Content-Type': 'application/json', // Set appropriate content type
      },
    })
    return response;

  } catch (error) {
    throw error;
  }
}
export const updateSalaryComponentApi = async (rowId, data) => {
  var datataken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_SALARY_COMPONENT_URL}${rowId}/`, data, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": 'application/json',
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}
export const UpdateEmployeeStatus = async (data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.put(apiUrl.UPDATE_EMPLOYEE_STATUS, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const FetchEarningsSalary = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.FETCH_EARNINGS_SALARY}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    })
    return response;
  } catch (error) {
    throw error;
  }
}

//Fetch Leave comp

export const FetchLeaveComponent = async (userId) => {
  var datatoken = getUserToken();

  try {
    const response = await axios.get(`${apiUrl.FETCH_LEAVE_COMP_URL}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    })
    return response;

  } catch (error) {
    throw error;
  }
}

export const AddLeaveComponet = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_LEAVE_COMPONENT_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const UpdateLeaveComp = async (rowId, data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_LEAVE_COMPONENT_URL}${rowId}/`, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const DeleteLeavecompApi = async (userId) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.delete(`${apiUrl.DELETE_LEAVE_COMP}/${userId}/`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const FetchDepartmentcomp = async (userId) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_DEPARTMENTCOMP_URL}/${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    }

    );
    return response;


  } catch (error) {
    throw error;
  }
}

export const UpdateDepartcomp = async (rowId, data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_DEPARTMENT_URL}/${rowId}/`, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const AddDeparmentcomp = async (data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_DEPARTMENT_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const DeletedepcompApi = async (userId) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.delete(`${apiUrl.DELETE_DEPARTMENT_URL}/${userId}/`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const FetchDesigApi = async (userId) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_DESIGNATION_URL}/${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",

      }
    })
    return response;

  } catch (error) {
    throw error;
  }
}

export const Adddepartcomp = async (data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_DESIGNATION_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;

  } catch (error) {
    throw error;
  }
}
export const UpdateDesigncomp = async (rowId, data) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.put(`${apiUrl.UPDATE_DESIGNATION_URL}/${rowId}/`, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const DeletedesignationcompApi = async (userId) => {
  const datatoken = getUserToken();
  try {
    const response = await axios.delete(`${apiUrl.DELETE_DESIGNATION_URL}${userId}/`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const FetchDepartmentHead =async(userId)=>{
const datatoken  = getUserToken();
try{
  const response = await axios.get(`${apiUrl.VIEW_DEPARTMENT_HEAD_URL}${userId}`,{
    headers:{
      Authorization:`Bearer ${datatoken}`,
      "Content-Type": "multipart/form-data",

    }
  });
  return response;
}catch(error){
  throw error;

}

}

export const UpdateDepartmentHead = async(rowid,data)=>{
  const datatoken = getUserToken();
  try{
const response = await axios.put(`${apiUrl.UPDATE_DEPARTMENT_HEAD_URL}${rowid}/`,data,{
  headers:{
    Authorization: `Bearer ${datatoken}`,
    "Content-Type": 'application/json',

  }
});
return response;
  }catch(error){
throw error;
  }

}

export const Deletedepheadapi = async(userid)=>{
const datatoken = getUserToken();
try{
  const response = await axios.delete(`${apiUrl.DELETE_DEPARTMENT_HEAD_URL}${userid}/`,{
    headers: {
      Authorization: `Bearer ${datatoken}`,
      "Content-Type": "multipart/form-data",
    }
  });
return response;
}  catch(error){
    throw error
}
}

export const AddDepartHeadApi =async(data) =>{
  const datatoken = getUserToken();

try{
const response = await axios.post(apiUrl.ADD_DEPARTMENT_HEAD_URL,data,{
  headers: {
    Authorization: `Bearer ${datatoken}`,
    "Content-Type": "multipart/form-data",
  }
})
return response;
}catch(error){
throw error;
}
}


export const FetchDepartmenttl = async(userid)=>{
  const datatoken = getUserToken();
  try{
const response = await axios.get(`${apiUrl.VIEW_DEPARTMENT_TL_URL}${userid}`,{
  headers:{
    Authorization:`Bearer ${datatoken}`,
    "Content-Type": "multipart/form-data",
  }
});
return response;
  }catch(error){
throw error;
  }
}

export const Deletedeptlapi = async(userid)=>{
  const datatoken = getUserToken();
  try{
    const response = await axios.delete(`${apiUrl.DELETE_DEPARTMENTTL_URL}${userid}/`,{
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
  return response;
  }  catch(error){
      throw error
  }
  }
  export const UpdateDepartmenttl = async(rowid,data)=>{
    const datatoken = getUserToken();
    try{
  const response = await axios.put(`${apiUrl.UPDATE_DEPARTMENTTL_URL}${rowid}/`,data,{
    headers:{
      Authorization: `Bearer ${datatoken}`,
      "Content-Type": 'application/json',
  
    }
  });
  return response;
    }catch(error){
  throw error;
    }
  
  }
  export const AddDepartTlApi =async(data) =>{
    const datatoken = getUserToken();
  
  try{
  const response = await axios.post(apiUrl.ADD_DEPARTMENTTL_URL,data,{
    headers: {
      Authorization: `Bearer ${datatoken}`,
      "Content-Type": "multipart/form-data",
    }
  })
  return response;
  }catch(error){
  throw error;
  }
  }
  
export const Fetchorganization=async(userid)=>{
  const datatoken = getUserToken();

  try{
const response = await axios.get(`${apiUrl.VIEW_ORGANIZATION_PROFILE}${userid}`,{
  headers:{
    Authorization: `Bearer ${datatoken}`,
    "Content-Type": "multipart/form-data",

  }
});
return response;
  }catch(error){
throw error;
  }
}

export const UpdateCompanyDetails = async(userid,data)=>{
const datatoken = getUserToken();
try{
  const response = await axios.put(`${apiUrl.UPDATE_COMPANY_URL}/${userid}/`,data,{
headers:{
  Authorization: `Bearer ${datatoken}`,
  "Content-Type": "multipart/form-data",

}
  });
  return response;

}catch(error){
  throw error;
}
}

export const AddCompanyDetailsApi = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_COMPANYDETAILS_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const UpdateUserProfile = async(userid,data)=>{
  const datatoken = getUserToken();
  try{
    const response = await axios.put(`${apiUrl.UPDATE_USERDETAILS_UTL

    }/${userid}/`,data,{
  headers:{
    Authorization: `Bearer ${datatoken}`,
    "Content-Type": "multipart/form-data",
  
  }
    });
    return response;
  
  }catch(error){
    throw error;
  }
  }

  export const ChangePassword =async(id,data) =>{
    const datatoken = getUserToken();
  
  try{
  const response = await axios.post(`${apiUrl.CHANGE_PASSWORD_URL}${id}`,data,{
    headers: {
      Authorization: `Bearer ${datatoken}`,
      "Content-Type": "multipart/form-data",
    }
  })
  return response;
  }catch(error){
  throw error;
  }
  }

export const AddFeedback =async(data)=>{
const datatoken = getUserToken();
try{
const response = await axios.post(`${apiUrl.ADD_FEEDBACK}`,data,{
headers:{
  Authorization: `Bearer ${datatoken}`,
  "Content-Type": "multipart/form-data",
}
});
return response;
}catch(error){
  throw error;
  }
  }
export const ViewHoliday = async(userid) =>{
  const datatoken = getUserToken();
  try{
const response = await axios.get(`${apiUrl.VIEW_HOLIDAY}/${userid}`,{
  headers:{
    Authorization: `Bearer ${datatoken}`,
    "Content-Type": "multipart/form-data",

  }
});
return response;
  }catch(error){
    throw error;

  }
}

export const AddHoliday =async(data)=>{
  const datatoken = getUserToken();
  try{
    const response = await axios.post(`${apiUrl.ADD_HOLIDAY}` ,data ,{
      headers:{
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": 'application/json',
    
      }
    });
    return response;

  }catch(error){
    throw error;
  }
}
export const AddNotesApi = async (data) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.post(apiUrl.ADD_NOTES_URL, data, {
      headers: {
        Authorization: `Bearer ${datatoken}`, // Use the token value directly
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

 
export const DeleteHolidayApi = async(userid)=>{
  const datatoken = getUserToken();
  try{
    const response = await axios.delete(`${apiUrl.DELETE_HOLIDAY}${userid}/`,{
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
  return response;
  }  catch(error){
      throw error
  }
  }
  export const StaffAllDetailsApi = async (userId) => {
    var datatoken = getUserToken();
    try {
      const response = await axios.get(`${apiUrl.STAFF_ALL_DETAILS}${userId}`, {
        headers: {
          Authorization: `Bearer ${datatoken}`,
          "Content-Type": "multipart/form-data",
        }
      });
      return response;
    }
    catch (error) {
      throw error;
    }
  }

  // View Leave Staff 

export const ViewStaffLeaveApi = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.STAFF_LEAVE_VIEW}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

//View Staff Attendance

export const viewStaffAttendanceApi = async (userId) => {
  var datataken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_EMP_ATTENDANCE_COUNT}${userId}`, {
      headers: {
        Authorization: `Bearer ${datataken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

//view Notification
export const Viewnotification = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_NOTIFICATION}${userId}`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// view dashboard counts

export const Viewdashboardcount = async (userId) => {
  var datatoken = getUserToken();
  try {
    const response = await axios.get(`${apiUrl.VIEW_DASHBOARD_COUNT}${userId}/`, {
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
    return response;
  }
  catch (error) {
    throw error;
  }
}

// staff leave delete
export const DeleteLeavestaffApi = async(userid)=>{
  const datatoken = getUserToken();
  try{
    const response = await axios.delete(`${apiUrl.DELETE_STAFF_LEAVE}${userid}/`,{
      headers: {
        Authorization: `Bearer ${datatoken}`,
        "Content-Type": "multipart/form-data",
      }
    });
  return response;
  }  catch(error){
      throw error
  }
  }