import React ,{useState}from 'react'
import '../css/popup.css'
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
import { AddDeparmentcomp } from '../../../Config/api';
import { toast } from 'react-toastify';
import{addDepartment} from '../../../Slice/departmentSlice';
import { Store } from '../../../Store';

const Adddep = ({show ,close ,sendDataToParent}) => {
  const userDetails = getUserDetails();
  const company_details = getCompanyDetails();

const [errors, setErrors] = useState({});
const [loader , setloader]=useState(false);
const[editdepcomp ,setdepcom]=useState({
  depart_name:'',
  user:userDetails.id,
  company : company_details.company_id
});
const handleInputchange=(event)=>{
  const{name,value}=event.target;
  setdepcom((prev)=>({
      ...prev,
      [name]:value,
  }))
} 

const handlesubmit = async() =>{
  const fieldData =[
    {
      name:"depart_name",
      type:"input",
      err:"Department Name",
      conditions:[
          (value)=>typeof value ==="string",
          (value)=>value.trim() !=="",
          (value)=>/^[a-zA-Z\s]+$/.test(value),
      ]
    }
  ]
  const fieldOrder=[
    "depart_name"
];
const newErrors ={};
for (const fieldName of fieldOrder){
  const fieldInfo = fieldData.find((field)=>field.name===fieldName);
  if(!fieldInfo){
      continue;
  }
  const fieldType = fieldInfo.type;
  const fieldLabel = fieldInfo.err;
  const value = editdepcomp[fieldName];
  const isFieldValid = fieldInfo.conditions.every((condition)=>typeof condition ==="function"?condition
(value):condition
);
if(!isFieldValid){
  if(value.trim() ===""){
      newErrors[fieldName] =`${fieldLabel} is Required`;

  }else{
      newErrors[fieldName]=`Please Enter a Valid ${fieldLabel}.`;
  }
  const inputField = document.querySelector(
      `${fieldType}[name ="${fieldName}"]`
  );
  if(inputField){
      inputField.focus();
      break;
  }
}
}
setErrors(newErrors);

if (Object.keys(newErrors).length===0){
  try{
 const response = await AddDeparmentcomp(editdepcomp);
 if(response){
  setloader(true);

  sendDataToParent(response.data.department);
  Store.dispatch(addDepartment(response.data.department))
  close();
  toast.success ('Department Component Added Sucessfully!',{
    position:"top-center",
    autoClose:2000,
    closeButton:false,
    className:'custom_toast'
  })
  setdepcom((prev)=>({
    ...prev,
    depart_name:'',
}))
 }
  }catch(error){
    const errorMessage =
    error.response && error.response.data && error.response.data.data
      ? error.response.data.data
      : "Add Department Failed. Please try again.";

  setErrors((prevErrors) => ({
    ...prevErrors,
    response_error: errorMessage,
  }));

  toast.error("Add Department Failed. Please try again.", {
    position: "top-center",
    autoClose: 2000,
    closeButton: false,
  }); 
  }finally{
    setloader(false);
  }
}
}
    if (!show) return null
  return (
    <div className='popup-section'>
           <div className='popup-container'>
           <span className='uploadexcel-close' onClick={close}>&times;</span>

            <div className='popup-container-sub'>
            <div className='addleave-heading'>
            <p>Add Department</p>
    
            </div>

        <div className='addleave-strightline'></div> 
        <div className='popup-content-viewing'>
        <div className='popup-inputfield'>
            <div className='popup-inputfield-sub'>
            <label> Department<span className='registeration-mandatory'> *</span> </label>
            <input
                  className=''
                  type='text'
                  name='depart_name'
                  onChange={handleInputchange}
                  value={editdepcomp.depart_name}
                />          
                {errors.depart_name && (<div className='error-message'>{errors.depart_name}</div>)}            </div>
        </div>
        {
  errors.response_error && (
    <div className='error-message-view salry-comp-err'>
      {errors.response_error.component}
    </div>
  )
}
        <div className='popup-button'>
        <button onClick={handlesubmit}>{loader ?<span className='spinner'></span>:"Submit"}</button>

        </div>
        </div>
            </div>
            
            </div>
    </div>
 
  )
}

export default Adddep