import React, { useState } from 'react';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import './Changepassword.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { ChangePassword } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';

const Changepassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const user_details = getUserDetails();
  const [errors, setErrors] = useState({}); 
  const navigate = useNavigate();
  // const logout = () => {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   localStorage.removeItem('company');
  //   navigate('/');
  // };  
  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
const[formData,setFormData]=useState({
  old_password:'',
  new_password:'',
  confirm_password:'',
  user:user_details.id,
})
const handleinputchange=(e)=>{
  const{name,value} = e.target;
setFormData((prev)=>({
...prev,
[name]:value,
}))
}
const handlesubmit = async()=>{
  let newErrors = {};
  if (formData.old_password.trim() === "") {
    newErrors.old_password = "Old Password is required";
  }
  if (formData.new_password.trim() === "") {
    newErrors.new_password = "New Password is required";
  }
  if (formData.confirm_password.trim() === "") {
    newErrors.confirm_password = "Confirm Password is required";
  }
  setErrors(newErrors);

  if (Object.keys(newErrors).length === 0) {

  try{
    setLoading(true)
const response= await ChangePassword(`?register_id=${user_details.id}`,formData);
if(response){
  toast.success ('Password Changed Sucessfully!',{
    position:"top-center",
    autoClose:2000,
    closeButton:false,
    className:'custom_toast'
  })
  setFormData((state) => ({
    ...state,
    old_password:'',
    new_password:'',
    confirm_password:'',
  }));
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('company');
  navigate('/login');
}
  }catch(error){
    if (error.response && error.response.data) {
      const errorData = error.response.data.data;
      newErrors = {};
      console.log(errorData, "erfiuedofjueoirhjefrhjekhk")
      for (const key in errorData) {
        if (errorData.hasOwnProperty(key)) {
          newErrors[key] = errorData[key]; // Assign the specific error message to the respective field
        }
      }

      setErrors(newErrors);
    } else {
      toast.error('Failed. Please try again.', {
        position: "top-center",
        autoClose: 2000,
        closeButton: false,
        className: 'custom_toast'

      });

      // Handle generic errors
      setErrors({
        response_error: 'An unexpected error occurred. Please try again later.',
      });
    }
  }finally{
    setLoading(false)

  }
  }
}
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p>Change Password</p>
                </div>
                <div className='pages-line'></div>
              </div>
              <div className='pages-maincontent'>
                <div className='changepassword-index'>
                  <div className='changepassword-card'>
                    <div className='changepassword-heading'>
                      <h3>RESET YOUR PASSWORD</h3>
                    </div>
                    <div className='changepassword-card-sub'>
                      <div className='changepassword-form-group'>
                        <label htmlFor='oldPassword'>
                          Old Password<span className='registeration-mandatory'>*</span>
                        </label>
                        <div className='password-input-container'>
                          <input
                            type={showOldPassword ? 'text' : 'password'}
                            id='oldPassword'
                            onChange={handleinputchange}
                            required
                            name='old_password'
                          />
                          <FontAwesomeIcon
                            className='password-icon'
                            icon={showOldPassword ? faEye : faEyeSlash}
                            onClick={toggleOldPasswordVisibility}
                          />
                        </div>
                        {errors.old_password && (
                              <div className="registeration-error-view">{errors.old_password}</div>
                            )}
                      </div>
                    
                      <div className='changepassword-form-group'>
                        <label htmlFor='newPassword'>
                          New Password<span className='registeration-mandatory'>*</span>
                        </label>
                        <div className='password-input-container'>
                          <input
                            type={showNewPassword ? 'text' : 'password'}
                            id='newPassword'
                            required
                            onChange={handleinputchange}
                            name='new_password'
                          />  
                          <FontAwesomeIcon
                            className='password-icon'
                            icon={showNewPassword ? faEye : faEyeSlash}
                            onClick={toggleNewPasswordVisibility}
                          />
                        </div>
                        {errors.new_password && (
                              <div className="registeration-error-view">{errors.new_password}</div>
                            )}
                      </div>
                      <div className='changepassword-form-group'>
                        <label htmlFor='confirmPassword'>
                          Confirm Password<span className='registeration-mandatory'>*</span>
                        </label>
                        <div className='password-input-container'>
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id='confirmPassword'
                            required
                            onChange={handleinputchange}
                            name='confirm_password'
                          />
                          <FontAwesomeIcon
                            className='password-icon'
                            icon={showConfirmPassword ? faEye : faEyeSlash}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        </div>
                        {errors.confirm_password && (
                              <div className="registeration-error-view">{errors.confirm_password}</div>
                            )}
                      </div>
                     
                      <div className='changepassword-submit-button'>
                        <button onClick={handlesubmit}>{loading ?<span className='spinner'></span>:"Submit"}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
