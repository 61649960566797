import React, { useState, useEffect, useCallback } from 'react';
import './Viewattncal.css';
import { formatTime } from '../../Component/TimeFormat';
import { viewSingleAttendanceApi } from '../../../Config/api';
import { getUserDetails } from '../../../Services/Storage';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Calendar = ({ show, close, responseData, selMonth, selYear, staffId }) => {
  const [currentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState([]);
  const [currentYear, setCurrentYear] = useState("");
  const [currentMonthIndex, setCurrentMonthIndex] = useState("");
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [holidayDetails, setHolidayDetails] = useState([]);
  const [lateData, setLateData] = useState([]);
  const formattedYear = currentYear;
  const user_details = getUserDetails ();

  useEffect(() => {
    if (responseData) {
      if (responseData.data) {
        setAttendanceDetails(responseData.data.attendance_details || []);
        setLeaveDetails(responseData.data.leave_details || []);
        setHolidayDetails(responseData.data.holiday_details || []);
        setLateData(responseData.data.late_details || []);
        const selectedYear = selYear || currentDate.getFullYear();
        const selectedMonth = selMonth ? selMonth - 1 : currentDate.getMonth();
        setCurrentYear(selectedYear);
        setCurrentMonthIndex(selectedMonth);
      }
      else
      {
        const selectedYear = selYear || currentDate.getFullYear();
        const selectedMonth = selMonth ? selMonth - 1 : currentDate.getMonth();
        setCurrentYear(selectedYear);
        setCurrentMonthIndex(selectedMonth);
      }
    }
  }, [responseData, selMonth, selYear, currentDate]);

  const generateCalendar = useCallback((year, month, attendanceDetails, leaveDetails, holidayDetails) => {
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar = [];

    let week = [];
    for (let i = 0; i < firstDay; i++) {
      week.push(null);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      const attendance = Array.isArray(attendanceDetails) ? attendanceDetails.find(att => att.date === currentDate) : undefined;
      // const leave = Array.isArray(leaveDetails) ? leaveDetails.find(leave => leave.from_date === currentDate) : undefined;
      // const leave = Array.isArray(leaveDetails) ? leaveDetails.find(leave => leave.from_date === currentDate || leave.to_date === currentDate) : undefined;
      const leave = Array.isArray(leaveDetails) 
      ? leaveDetails.find(leave => 
      new Date(leave.from_date) <= new Date(currentDate) && 
      new Date(leave.to_date) >= new Date(currentDate)
      ) 
      : undefined;

      const holiday = Array.isArray(holidayDetails) 
      ? holidayDetails.find(holiday => 
      new Date(holiday.holiday_from_date) <= new Date(currentDate) && 
      new Date(holiday.holiday_end_date) >= new Date(currentDate)
      ) 
      : undefined;
      // const holiday = Array.isArray(holidayDetails) ? holidayDetails.find(holiday => holiday.holiday_date === currentDate)
      week.push({ day, attendance, leave, holiday });
      if (week.length === 7) {
        calendar.push(week);
        week = [];
      }
    }

    if (week.length > 0) {
      while (week.length < 7) {
        week.push(null);
      }
      calendar.push(week);
    }
    console.log("week list ", week);
    setCurrentMonth(calendar);
  }, []);

  useEffect(() => {
    generateCalendar(currentYear, currentMonthIndex, attendanceDetails, leaveDetails, holidayDetails);
  }, [currentYear, currentMonthIndex, attendanceDetails, leaveDetails, holidayDetails, generateCalendar]);

  const handlePrevMonth = async () => {
    const newDate = new Date(currentYear, currentMonthIndex - 1, 1);
    const year = newDate.getFullYear();
    const monthIndex = newDate.getMonth(); // Get the zero-based month index
    const month = (monthIndex + 1).toString().padStart(2, '0'); // Convert to one-based and pad
    try {
      const response = await viewSingleAttendanceApi(`?register_id=${staffId}&month=${month}&year=${year}&admin_id=${user_details.id}`);
      if (response) {
        setAttendanceDetails(response.data.attendance_details || []);
        setLeaveDetails(response.data.leave_details || []);
        setHolidayDetails(response.data.holiday_details || []);
        setLateData(response.data.late_details || []);
        setCurrentYear(year);
        setCurrentMonthIndex(monthIndex); // Set as numeric index
      }
    } catch (error) {
      console.error("Error fetching previous month data:", error);
    }
  };
  
  const handleNextMonth = async () => {
    const newDate = new Date(currentYear, currentMonthIndex + 1, 1);
    const year = newDate.getFullYear();
    const monthIndex = newDate.getMonth(); // Get the zero-based month index
    const month = (monthIndex + 1).toString().padStart(2, '0'); // Convert to one-based and pad
  
    try {
      const response = await viewSingleAttendanceApi(`?register_id=${staffId}&month=${month}&year=${year}&admin_id=${user_details.id}`);
      if (response) {
        setAttendanceDetails(response.data.attendance_details || []);
        setLeaveDetails(response.data.leave_details || []);
        setHolidayDetails(response.data.holiday_details || []);
        setLateData(response.data.late_details || []);
        setCurrentYear(year);
        setCurrentMonthIndex(monthIndex); // Set as numeric index
      }
    } catch (error) {
      console.error("Error fetching next month data:", error);
    }
  };
  

  if (!show) return null;
  if (!responseData) return null;

  return (
    <div className='calendar-section1'>
      <div className="calendar">
        <span className='calendar-close' onClick={close}>&times;</span>
        <div className="calendar-header">
          <button onClick={handlePrevMonth}>&lt;</button>
          <div>{new Date(currentYear, currentMonthIndex).toLocaleString('default', { month: 'long' })} {formattedYear}</div>
          <button onClick={handleNextMonth}>&gt;</button>
        </div>
        <div className="calendar-body">
          <div className='calendar-body-first-child'>
          <div className="calendar-days">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="calendar-day">
                {day}
              </div>
            ))}
          </div>
          {currentMonth.map((week, weekIndex) => (
            <div key={weekIndex} className="calendar-week">
              {week.map((dayInfo, dayIndex) => (
                <div
                  key={dayIndex}
                  className={`calendar-date ${dayInfo ? 'active' : ''} ${!dayInfo || (!dayInfo.attendance && !dayInfo.leave && !dayInfo.holiday) ? 'blurred' : ''}`}
                >
                  {dayInfo ? (
                    <>
                      <div className='cal-day'>{dayInfo.day}</div>
                      { dayInfo.holiday?.reason ? (
                        <div className='cal-meeage holiday-cal-message'>Holiday {dayInfo.holiday.reason}</div>
                      ) : dayInfo.leave?.status ? (
                        dayInfo.leave.leave_deduct === 'Halfday' ? 
                        (
                          <>
                          <div className='cal-meeage punched-cal-message'>{formatTime(dayInfo.attendance.punch_in_time)} - {formatTime(dayInfo.attendance.punch_out_time)}</div>
                          <div className='cal-meeage leave-cal-message'>{`${dayInfo.leave.leave_type} - ${dayInfo.leave.reason}` }</div>
                          
                          {/* <span className='cal-message-working-hour'>{`W H : ${dayInfo.attendance.total_hr}`}</span> */}
                          </>
                        )
                        :
                        (
                          <div className='cal-meeage leave-cal-message'>{`${dayInfo.leave.leave_type} - ${dayInfo.leave.reason}` }</div>
                        )
                      ) :  dayInfo.attendance ? (
                        <>
                          <div className='cal-meeage punched-cal-message'>{formatTime(dayInfo.attendance.punch_in_time)} - {formatTime(dayInfo.attendance.punch_out_time)}</div>
                          {/* <span className='cal-message-working-hour'>{`W H : ${dayInfo.attendance.total_hr}`}</span> */}
                        </>
                      ) : (
                        <div className='cal-meeage missedpunch-cal-message'>Missed Punch</div>
                      )}
                    </>
                  ) : null}
                </div>
              ))}
            </div>
            
          ))}
          <div className='cal-footer-content-div'> 
              <div className='cal-footer-content-div-child'>
                <span> Morning Late : {lateData.total_mrg_late}</span>
                <span> Early By : {lateData.total_evg_late}</span>
              </div>
              <div className='cal-footer-content-div-child'> 
                <span> Total Late : {lateData.total_late_hour}</span>
              </div>
              <div className='cal-footer-content-div-child'> 
                <span> Permission Hour : {lateData.permission_hour}</span>
                <span> Permission Exceed : {lateData.permission_exceed_time}</span>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
