import React ,{useState,useEffect} from 'react'
import { getCompanyDetails, getUserDetails } from '../../../Services/Storage';
import { UpdateDepartcomp } from '../../../Config/api';
import { toast } from 'react-toastify';
import {updatedepartcomp} from '../../../Slice/departmentSlice'
import { Store } from '../../../Store';

const Editdep = ({show ,close,editData ,updateData}) => {
const userDetails = getUserDetails();
const company_details = getCompanyDetails();

const [errors, setErrors] = useState({});
const [loader , setloader]=useState(false);

const[editdepcomp ,setdepcom]=useState({
    depart_name:'',
    user:userDetails.id,
    company : company_details.company_id

});
const handleInputchange=(event)=>{
    const{name,value}=event.target;
    setdepcom((prev)=>({

        ...prev,
        [name]:value,

    }))
    
}

const handlesubmit = async()=>{
    const fieldData =[
      {
        name:"depart_name",
        type:"input",
        err:"Department Name",
        conditions:[
            (value)=>typeof value ==="string",
            (value)=>value.trim() !=="",
            (value)=>/^[a-zA-Z\s]+$/.test(value),
        ]
      }
    ]
    const fieldOrder=[
        "depart_name"
    ];
    const newErrors ={};
    for (const fieldName of fieldOrder){
        const fieldInfo = fieldData.find((field)=>field.name===fieldName);
        if(!fieldInfo){
            continue;
        }
        const fieldType = fieldInfo.type;
        const fieldLabel = fieldInfo.err;
        const value = editdepcomp[fieldName];
        const isFieldValid = fieldInfo.conditions.every((condition)=>typeof condition ==="function"?condition
    (value):condition
    );
    if(!isFieldValid){
        if(value.trim() ===""){
            newErrors[fieldName] =`${fieldLabel} is Required`;

        }else{
            newErrors[fieldName]=`Please Enter a Valid ${fieldLabel}.`;
        }
        const inputField = document.querySelector(
            `${fieldType}[name ="${fieldName}"]`
        );
        if(inputField){
            inputField.focus();
            break;
        }
    }
    }
    setErrors(newErrors);
    if(Object.keys(newErrors).length===0){

    
try{
    setloader(true);
  const response = await (UpdateDepartcomp(editData.id,editdepcomp))  ;
if(response){
    console.log(response.updated_department);
    updateData(response.updated_department);
    Store.dispatch(updatedepartcomp(response.updated_department));
    close();
    toast.success ('Department Updated Sucessfully!',{
        position:"top-center",
        autoClose:2000,
        closeButton:false,
        className:'custom_toast'
      })
}else{
    console.log("No data received in response.");

}
}catch (error){
    const errorMessage =
    error.response && error.response.data && error.response.data.data
      ? error.response.data.data
      : "Update Department Failed. Please try again.";

  setErrors((prevErrors) => ({
    ...prevErrors,
    response_error: errorMessage,
  }));

  toast.error("Update Department Failed. Please try again.", {
    position: "top-center",
    autoClose: 2000,
    closeButton: false,
  }); 
}finally{
    setloader(false);
}
}
}

useEffect(()=>{

    if(editData){
        setdepcom({
            depart_name:editData.depart_name,
            user:userDetails.id,
        })
    }
},[editData ,userDetails.id])

    if (!show) return null
  return (
    <div className='popup-section'>
           <div className='popup-container'>
           <span className='uploadexcel-close' onClick={close}>&times;</span>

            <div className='popup-container-sub'>
            <div className='addleave-heading'>
            <p>Edit Department</p>
    
            </div>

        <div className='addleave-strightline'></div> 
        <div className='popup-content-viewing'>
        <div className='popup-inputfield'>
            <div className='popup-inputfield-sub'>
            <label> Department<span className='registeration-mandatory'> *</span> </label>
            <input
                  className=''
                  type='text'
                  name='depart_name'
                  onChange={handleInputchange}
                  value={editdepcomp.depart_name}
                />          
                {errors.depart_name && (<div className='error-message'>{errors.depart_name}</div>)}
                  </div>
        </div>
        {
  errors.response_error && (
    <div className='error-message-view salry-comp-err'>
      {errors.response_error.component}
    </div>
  )
}
        <div className='popup-button'>
        <button onClick={handlesubmit}>{loader ?<span className='spinner'></span>:"Submit"}</button>

        </div>
        </div>
            </div>
            
            </div>
    </div>
 
  )
}

export default Editdep