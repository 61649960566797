import React, { useState } from 'react';
import './Registeration.css';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Registration } from '../../Config/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../Services/Authentication';
import Register_img from '../../Images/Register/Registerpage';

const Registeration = () => {

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    password: '',
    city: '',
    state: '',
    pincode: '',
    role: "company"
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };  
const togglePasswordVisibility =() =>{
  setShowPassword(!showPassword)
}
const handleSubmit = async () => {
  const newErrors = {};
  if (formData.name.trim() === "") {
    newErrors.name = "Name is required";
  } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
    newErrors.name =
      " Name must contain only alphabetic characters and spaces";
  }    if (formData.phone.trim() === "") {
    newErrors.phone = "Phone is required";
  } else if (!/^\d+$/.test(formData.phone)) {
    newErrors.phone = "Phone must contain only digits";
  } else if (formData.phone.length < 10 || formData.phone.length > 13) {
    newErrors.phone = "Phone must be between 10 to 13 digits";
  }    if (formData.email.trim() === '') {
    newErrors.email = 'Email is required';
  } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
    newErrors.email = 'Invalid email format';
  }    if (formData.password.trim() === '') {
    newErrors.password = 'Password is required';
  }
  if (formData.password.length < 8) {
    newErrors.password = "The password must contain 8 Characters.";
  } else if (
    !formData.password ||
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(
      formData.password
    )
  ) {
    newErrors.password =
      "The password must contain digits, uppercase letters, and lowercase letters."
      ;
  }    if (formData.address_line1.trim() === '') {
    newErrors.address_line1 = 'Address Line1 is required';
  }    if (formData.address_line2.trim() === '') {
    newErrors.address_line2 = 'Address Line2 is required';
  }    if (formData.city.trim() === '') {
    newErrors.city = 'City is required';
  }    if (formData.state.trim() === '') {
    newErrors.state = 'State is required';
  }
  if (formData.pincode.trim() === '') {
    newErrors.pincode = 'Pincode is required';
  } else if (!/^\d+$/.test(formData.pincode)) {
    newErrors.pincode = 'Pincode must contain only digits';
  }
  setErrors(newErrors);    
  if (Object.keys(newErrors).length === 0) {
    try {
      setLoading(true);
      const response = await Registration(formData);
      if(response)
        {
          toast.success('Signup successful!', {
          position: "top-center",
        autoClose: 2000,
        closeButton: false,
        className:'custom_toast'
      });
      setTimeout(() => {
        navigate('/compdetails');
      }, 1000); // Navigate after 2 seconds (2000 ms)
        }
    }
    catch (error) {
      if (error.response) {
        const newErrors = {};
        newErrors.response_error = error.response.data.data;
        setErrors(newErrors);
      }
      else {
        toast.error('Signup failed. Please try again.', {
          position: "top-center",  // Use a string directly for the position
          autoClose: 2000,
          closeButton: false,
        });
        const newErrors = {
          response_error: [],
        };          for (const key in error.response.data.errors) {
          if (error.response.data.errors.hasOwnProperty(key)) {
            newErrors.response_error.push(`${error.response.data.errors[key]}`);
          }
        }
        setErrors(newErrors);
        setFormData((state) => ({
          ...state,
        }));
      }
    }
    finally {
      setLoading(false);
    }
  }
}  

if(isAuthenticated())
  {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className='registeration-container'>
      <div className='registeration-container-sub'>
        <div className='registeration-content'>
          <div className='register-split'>
                          <p className='para-reg'>Create an account to get started on your journey with us  :)</p>

            <div className='register-img-view'>
              <div className='register-img-view-sub'>

              <img src={Register_img.registerimg}alt='img'/>

              </div>
            </div>
          </div>
          <div className='register-split-1'>
          <div className='registeration-content-sub'>
            <div className='registeration-main'>
              {/* <div className='registeration-logo'>
                <img className="registeration-logo-img" src={logo.logo_img} alt="Login" />
              </div> */}
              <div className='registration-sub'>

                <div className='registeration-form-fields'>
                  <h3 className='reg-heading'>SIGN UP</h3>
                  <div className='registeration-input-row'>
                    <div className='registeration-input-group'>

                      <label htmlFor='first-input'> Name <span className='registeration-mandatory'>*</span></label>
                      <input id='first-input' type='text' onChange={handleInputChange} name='name' className='registeration-input-field' />
                      {errors.name && (
                <div className="registeration-error-view">{errors.name}</div>
              )}
                    </div>
                   {/* <div className='registeration-input-group'>
                      <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                      <input id='eighth-input' type='text' onChange={handleInputChange} name='state' className='registeration-input-field' />
                      {errors.state && (
                <div className="registeration-error-view">{errors.state}</div>
              )}
                    </div> */}
                  </div>
                  <div className='registeration-input-row'>
                    <div className='registeration-input-group'>
                      <label htmlFor='third-input'>Phone <span className='registeration-mandatory'>*</span></label>
                      <input id='third-input' type='text' onChange={handleInputChange} name='phone' className='registeration-input-field' />
                      {errors.phone && (
                <div className="registeration-error-view">{errors.phone}</div>
              )}
                    </div>
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='sixth-input'>City <span className='registeration-mandatory'>*</span></label>
                      <input id='sixth-input' type='text' onChange={handleInputChange} name='city' className='registeration-input-field' />
                      {errors.city && (
                <div className="registeration-error-view">{errors.city}</div>
              )}
                    </div> */}
                  </div>
                  <div className='registeration-input-row'>
                    <div className='registeration-input-group'>
                      <label htmlFor='fifth-input'>Email <span className='registeration-mandatory'>*</span></label>
                      <input id='fifth-input' type='text' onChange={handleInputChange} name='email' className='registeration-input-field' />
                      {errors.email && (
                <div className="registeration-error-view">{errors.email}</div>
              )}
                    </div>
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                      <input id='eighth-input' type='text' onChange={handleInputChange} name='state' className='registeration-input-field' />
                      {errors.state && (
                <div className="registeration-error-view">{errors.state}</div>
              )}
                    </div> */}
                  </div>
                  <div className='registeration-input-row'>
                    <div className='registeration-input-group'>
                      <label htmlFor='seventh-input'>Password <span className='registeration-mandatory'>*</span></label>
                      <div className='registeration-password-container'>
                        <input id='seventh-input' type={showPassword ? "text" : "password"} onChange={handleInputChange} name='password' className='registeration-input-field' />
                        <FontAwesomeIcon className="registeration-password-icon" icon={showPassword ? faEye : faEyeSlash} onClick={togglePasswordVisibility} />
                        
                      </div>
                      {errors.password && (
                <div className="registeration-error-view">{errors.password}</div>
              )}
                    </div>
                    
                
                  </div>
                  <div className='registeration-input-row'>
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='ninth-input'>Confirm Password</label>
                      <input id='ninth-input' type='text' className='registeration-input-field' />
                    </div> */}
                     <div className='registeration-input-group'>
                      <label htmlFor='second-input'>Address Line 1 <span className='registeration-mandatory'>*</span></label>
                      <input id='second-input' type='text' onChange={handleInputChange} name='address_line1' className='registeration-input-field input-cls-full' />
                      {errors.address_line1 && (
                <div className="registeration-error-view">{errors.address_line1}</div>
              )}
                    </div>
                    <div className='registeration-input-group'>
                      <label htmlFor='fourth-input'>Address Line 2 <span className='registeration-mandatory'>*</span></label>
                      <input id='fourth-input' type='text' onChange={handleInputChange} name='address_line2' className='registeration-input-field' />
                      {errors.address_line2 && (
                <div className="registeration-error-view">{errors.address_line2}</div>
              )}
                    </div>
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='tenth-input'>Pincode</label>
                      <input id='tenth-input' type='text' onChange={handleInputChange} name='pin_code' className='registeration-input-field' />
                    </div> */}
                  </div>
                  <div className='registeration-input-row'>
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='ninth-input'>Confirm Password</label>
                      <input id='ninth-input' type='text' className='registeration-input-field' />
                    </div> */}
                     {/* <div className='registeration-input-group'>
                      <label htmlFor='second-input'>Address Line 1 <span className='registeration-mandatory'>*</span></label>
                      <input id='second-input' type='text' onChange={handleInputChange} name='address_line1' className='registeration-input-field input-cls-full' />
                      {errors.address_line1 && (
                <div className="registeration-error-view">{errors.address_line1}</div>
              )}
                    </div> */}
                    <div className='registeration-input-group'>
                      <label htmlFor='sixth-input'>City <span className='registeration-mandatory'>*</span></label>
                      <input id='sixth-input' type='text' onChange={handleInputChange} name='city' className='registeration-input-field' />
                      {errors.city && (
                <div className="registeration-error-view">{errors.city}</div>
              )}
                    </div>
                      <div className='registeration-input-group'>
                      <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                      <input id='eighth-input' type='text' onChange={handleInputChange} name='state' className='registeration-input-field' />
                      {errors.state && (
                <div className="registeration-error-view">{errors.state}</div>
              )}
                    </div>
                    <div className='registeration-input-group'>
                      <label htmlFor='tenth-input'>Pincode <span className='registeration-mandatory'>*</span></label>
                      <input id='tenth-input' type='text' onChange={handleInputChange} name='pincode' className='registeration-input-field' />
                      {errors.pincode && (
                <div className="registeration-error-view">{errors.pincode}</div>
              )}
                    </div>
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='fourth-input'>Address Line 2 <span className='registeration-mandatory'>*</span></label>
                      <input id='fourth-input' type='text' onChange={handleInputChange} name='address_line2' className='registeration-input-field' />
                      {errors.address_line2 && (
                <div className="registeration-error-view">{errors.address_line2}</div>
              )}
                    </div> */}
                    {/* <div className='registeration-input-group'>
                      <label htmlFor='tenth-input'>Pincode</label>
                      <input id='tenth-input' type='text' onChange={handleInputChange} name='pin_code' className='registeration-input-field' />
                    </div> */}
                  </div>
                  <div >
                  {errors.response_error && (
                <div className="registeration-error-view">{errors.response_error}</div>
              )}
<div className='registeration-button-div'>
<button className='registeration-button' onClick={handleSubmit} disabled={loading}>
                        {loading ? <span className="spinner"></span> : 'Register'}
                      </button>
</div>
                  </div>
                  <div className='login-signup'>
              <p>Have a Staffin account?
                <a href="/login"> Sign in</a>
              </p>
            </div>
                </div>
              </div>
            </div>
          </div>
          </div>

        </div>
      </div>
    </div>

  );
};

export default Registeration;
