import React ,{useState, useEffect } from 'react';
import { Store } from '../../../Store';
import { updateSalaryComponent } from '../../../Slice/salaryComponentSlice';
import { toast } from 'react-toastify';
import { updateSalaryComponentApi } from '../../../Config/api';

const Editearning =({show ,close, editData, updateData})=>{

const [loader , setloader]=useState(false);

    console.log("update data" , editData)

    const [salarycomponent, setSalaryComponent] = useState({
        component_name: '',
        component: '',
        calculation_type: '',
        amount_type: '',
        calculation_code: '',
        earning_deduction: '',
        user: '',
      });
    
      useEffect(() => {
        if (editData) {
          setSalaryComponent({
            component_name: editData.component_name,
            component: editData.component,
            calculation_type: editData.calculation_type,
            amount_type: editData.amount_type,
            calculation_code: editData.calculation_code,
            earning_deduction: editData.earning_deduction,
            user: editData.user,
          });
        }
      }, [editData]);
      const [errors, setErrors] = useState({});
      const handleInputChange = (event) =>{
        const {name, value} = event.target;
        setSalaryComponent((preValue)=>({
          ...preValue,
          [name] : value,
        }))
      }

      
      const handleSubmit = async () =>{
        const fieldData = [
          {
            name: "component_name",
            type: "input",
            err: "Component Name",
            conditions: [
              (value) => typeof value === "string", // Check if the value is a string
              (value) => value.trim() !== "", // Check if the trimmed value is not empty
              (value) => /^[a-zA-Z\s]+$/.test(value), // Check if the value contains only letters and spaces
            ],
          },
          {
            name: "component",
            type: "input",
            err: "Component Type",
            conditions: [
              (value) => typeof value === "string", // Check if the value is a string
              (value) => value.trim() !== "", // Check if the trimmed value is not empty
              (value) => /^[a-zA-Z\s]+$/.test(value), // Check if the value contains only letters and spaces
            ],
          },
          {
            name: "amount_type",
            type: "input",
            err: "Amount Type",
            conditions: [
              (value) => !isNaN(value), // Check if the value is a number (integer or float)
              (value) => typeof parseFloat(value) === "number", // Ensure the parsed value is a number
              (value) => /^\d+(\.\d+)?$/.test(value), // Ensure the value is a valid integer or float
            ],
          },    
        ];
        const fieldOrder = [
          "component_name",
          "component",
          "amount_type"
        ];
        const newErrors = {};
        for (const fieldName of fieldOrder) {
          const fieldInfo = fieldData.find((field) => field.name === fieldName);
          if (!fieldInfo) {
            // Field not found in fieldData, handle accordingly
            continue;
          }
          const fieldType = fieldInfo.type;
          const fieldLabel = fieldInfo.err;
          const value = salarycomponent[fieldName];
          // Check conditions for the field
          const isFieldValid = fieldInfo.conditions.every((condition) =>
            typeof condition === "function" ? condition(value) : condition
          );
          if (!isFieldValid) {
            // Customize error messages based on conditions
            if (value.trim() === "") {
              newErrors[fieldName] = `${fieldLabel} is Required`;
            } else {
              newErrors[fieldName] = `Please Enter a Valid ${fieldLabel}.`;
            }
            // Focus on the first invalid field and stop checking further
            const inputField = document.querySelector(
              `${fieldType}[name="${fieldName}"]`
            );
            if (inputField) {
              inputField.focus();
              break;
            }
          }
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
        try{
          setloader(true);

            const response = await updateSalaryComponentApi(editData.id,salarycomponent);
            console.log("Full response: ", response);
            if (response && response.data && response.data.updated_salary_component
            ) {
              console.log("Received data: ", response.data.updated_salary_component
              );
              updateData(response.data.updated_salary_component
              );
              Store.dispatch(updateSalaryComponent(response.data.updated_salary_component));
              close();
              toast.success ('Earnings Updated Sucessfully!',{
                position:"top-center",
                autoClose:2000,
                closeButton:false,
                className:'custom_toast'
              })
            } else {
              console.log("No data received in response.");
            }
        }
        catch(error)
        {
          const errorMessage =
          error.response && error.response.data && error.response.data.data
            ? error.response.data.data
            : "Update Earnings Failed. Please try again.";
      
        setErrors((prevErrors) => ({
          ...prevErrors,
          response_error: errorMessage,
        }));
      
        toast.error("Update Earnings Failed. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          closeButton: false,
        }); 
        }finally{
          setloader(false);

        }
      }
    }

if (!show) return null
return(
    <div className='addsalrcomp-section'>
<div className='addsalrcomp-container'>
<div className='addsalrcomp-container-div1'>
<span className='uploadexcel-close' onClick={close}>&times;</span>
<div className='popup-container-sub'>
<div className='add-component-heading'>
<p>Update Earnings</p>
</div>
<div className='addleave-strightline'></div> 
<div className='sal-comp-form-div'>
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Component Name <span className='sal-comp-input-important'> * </span> </span>
    <input type='text' onChange={handleInputChange} value={salarycomponent.component_name} name='component_name'className='sal-comp-input' />
    {
      errors.component_name && (
        <div className='error-message'>{errors.component_name}</div>
      )
    }
  </div>
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Component Type  <span className='sal-comp-input-important'> * </span></span>
    <input type='text' onChange={handleInputChange}  value={salarycomponent.component} name='component'className='sal-comp-input' />
    {
      errors.component && (
        <div className='error-message'>{errors.component}</div>
      )
    }
  </div>
  <div className='sal-comp-input-div'>
  <span className='sal-comp-input-div-span-text' > Calculation Type <span className='sal-comp-input-important'> * </span> </span>
    {/* <span className='sal-comp-input-span-calc'>
      <span> 
        <span> Percentage of CTC </span>
        <input type='radio' name='calculation_type' value='ctc' onChange={handleInputChange} checked={salarycomponent.calculation_type === 'ctc'} />
      </span>
      <span> 
        <span> Percentage of Basic </span>
        <input type='radio' name='calculation_type' value='basic'  onChange={handleInputChange} checked={salarycomponent.calculation_type === 'basic'} />
      </span>
      <span> 
        <span> Flat Amount </span>
        <input type='radio' value='flat'  name='calculation_type' onChange={handleInputChange} checked={salarycomponent.calculation_type === 'flat'} />
      </span>
    </span> */}
    
    <select className='sal-comp-select-box' value={salarycomponent.calculation_type} name='calculation_type' onChange={handleInputChange}>

      <option value="ctc">Percentage of CTC</option>
      <option value="basic">Percentage of Basic</option>
      <option value="flat">Flat Amount</option>
      </select>
      
  </div>
  {salarycomponent.calculation_type === 'flat'?(
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Enter Amount <span className='sal-comp-input-important'> * </span> </span>
    {/* <RiMoneyRupeeCircleLine className='addsalrcomp-input-icon'/> */}
    <input type='text' onChange={handleInputChange} value={salarycomponent.amount_type}  name='amount_type'className='sal-comp-input' />
    {
      errors.amount_type && (
        <div className='error-message'>{errors.amount_type}</div>
      )
    }
  </div>
  ):(
  <div className='sal-comp-input-div'>
    <span className='sal-comp-input-div-span-text'> Enter Percentage of {salarycomponent.calculation_type} <span className='sal-comp-input-important'> * </span> </span>
    <input type='text' onChange={handleInputChange} name='amount_type' value={salarycomponent.amount_type}  className='sal-comp-input' />
    {
      errors.amount_type && (
        <div className='error-message'>{errors.amount_type}</div>
      )
    }
     {
  errors.response_error && (
    <div className='error-message-view salry-comp-err'>
      {errors.response_error.component}
    </div>
  )
}
  </div>
  )}
  <div className='sal-comp-button-div'>
  <button onClick={handleSubmit} disabled={loader} className='popup-button-cls'>{ loader ? <span className='spinner'></span>:"submit"}</button>
  </div>
</div>
</div>

</div>
</div>
</div>
)
}
export default Editearning