import React, { useState, useEffect, useCallback } from 'react'
import Header from '../../Header/Header'
import Sidebar from '../../Sidebar/Sidebar'
import Select from 'react-select';
import Calendar from '../Viewattncalender/Viewattncal';
import './Viewattendance.css';
// import { CiSearch } from 'react-icons/ci';
import getMonthYearData from '../../Component/MonthYearList';
import { viewSingleAttendanceApi, viewStaffAttendanceApi } from '../../../Config/api';
import { getStaffDetails, getUserDetails } from '../../../Services/Storage';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import pageloader from '../../../Loader/pageloading/Pageloader';
import ReactPaginate from "react-paginate";
import monthNames from '../../Component/Monthview';

const ViewStaffAttendance = () => {
  const staff_details = getStaffDetails();

  const [currentpage, setCurrentPage] = useState(1);
  const [iscalander, setopencalander] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [singleAttendance, setSingleAttendance] = useState([]);
  const [loading, setIsloading] = useState(false);
  const [totaldedcount, setdedcount] = useState(0)

  // const [search, setSearchword] = useState("");
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const user_details = getUserDetails();
  const per_page = 10;

  const handleChangeYear = (selecteOption) => {
    setYear(selecteOption.value);
  }
  const handleChangeMonth = (selecteOption) => {
    setMonth(selecteOption.value);
  }
  // const handleInputChange = (event) => {
  //   const { value } = event.target;
  //   setSearchword(value);
  // }
  const currentPageNo = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const openpopupclander = async (id, month, year) => {
    setMonth(month);
    setYear(year);
    setSelectedStaffId(id);
    try {
      const response = await viewSingleAttendanceApi(`?register_id=${id}&month=${month}&year=${year}&admin_id=${staff_details.register_details.user_id}`)
      if (response) {
        setSingleAttendance(response);
        setopencalander(true);
      }
    }
    catch (error) {

    }
  }
  const closepopupcalander = () => {
    setMonth('');
    setYear('');
    setopencalander(false)
  }



  const { months, years } = getMonthYearData();
  const keys = Object.keys(months);
  console.log(keys);
  const monthData = Object.keys(months).sort((a, b) => a - b).map(key => (
    {
      value: key,
      label: months[key],
    }));

  const yearData = Object.keys(years).map(key => ({
    value: years[key],
    label: years[key],
  }));

  const fetchAttendanceList = useCallback(async () => {
    try {
      setIsloading(true);
      const response = await viewStaffAttendanceApi(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&month=${month}&year=${year}&admin_id=${staff_details.register_details.user_id}`);
      console.log("leave data", response);
      if (response) {
        setAttendance(response.data.get_attendance);
        setdedcount(response.data.page_context.total)

      }
    }
    catch (error) {

    } finally {
      setIsloading(false);

    }
  }, [currentpage, per_page, month, year, user_details.id]);

  useEffect(() => {
    fetchAttendanceList();
  }, [fetchAttendanceList])


  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>

                  <p>Attendance Report</p>
                  <span className='header-search-add-parent'>
                    {/* <div className='pages-serach-view'>
                      <CiSearch className='page-search-icon' />
                      <input className='pages-serach' onChange={handleInputChange} placeholder='Search Employee ' type='search' />
                    </div> */}
                  </span>

                </div>
                <div className='viewlpageseave-line'></div>
              </div>
              <div className='pages-filter-options'>
                <div className='pages-filter-sub'>
                  <p>Filter:</p>
                  <Select className='pages-dropdown' options={yearData}
                    onChange={handleChangeYear}                   
                    placeholder="Select Year" />
                  <Select className='pages-dropdown' options={monthData} onChange={handleChangeMonth}                  
                    placeholder="Select Month" />

                </div>
              </div>
              <div className='pages-maincontent'>
                {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                    <table className='pages-table'>
                      <thead>
                        <tr>

                          <th>S.No</th>
                          <th>Month / Year</th>

                          <th>Punched</th>
                          <th>Leave</th>
                          <th>Holiday</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
  {attendance.length > 0 ? (
    attendance.map((atndnce, index) => {
      const monthData = atndnce.attendance_count.length > 0 || atndnce.leave_count.length > 0 || atndnce.holiday_count.length > 0 ? (
        atndnce.attendance_count.map((att, idx) => {
          const leave = atndnce.leave_count.find(l => l.month === att.month && l.year === att.year)?.count || 0;
          const holiday = atndnce.holiday_count.find(h => h.month === att.month && h.year === att.year)?.count || 0;

          return (
            <tr key={`${atndnce.registration_id}-${idx}`}>
              <td>{idx + 1}</td>
              <td>{monthNames[att.month]}/ {att.year}</td>
              <td>{att.count}</td>
              <td>{leave}</td>
              <td>{holiday}</td>
              <td onClick={() => openpopupclander(atndnce.registration_id, att.month, att.year)}>
                <div className='viewattn-view-button'>View</div>
              </td>
            </tr>
          );
        })
      ) : (
        <tr key={`${atndnce.registration_id}-no-data`}>
         <td colSpan={6} className="no-data-table">
        <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
      </td>
        </tr>
      );
      return monthData;
    })
  ) : (
    <tr>
      <td colSpan={6} className="no-data-table">
        <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
      </td>
    </tr>
  )}
</tbody>

                    </table>
                  )}
                <div style={{ float: "right" }} id="pagination_link"></div>
                <div style={{ padding: "20px" }}>
                  {totaldedcount > per_page ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {singleAttendance && (
        <Calendar
          show={iscalander}
          close={closepopupcalander}
          responseData={singleAttendance}
          selMonth={month}
          selYear={year}
          staffId={selectedStaffId}
        />
      )}

    </div>
  )
}

export default ViewStaffAttendance