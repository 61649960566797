import React, { useState ,useEffect ,useCallback} from 'react';
import { CiEdit, CiSearch, CiTrash } from 'react-icons/ci';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Adddepartmenthead from './Adddepartmenthead';
import { getUserDetails } from '../../../Services/Storage';
import { Deletedepheadapi, FetchDepartmentHead } from '../../../Config/api';
import Editdepartmenthead from './Editdepartmenthd';
import pageloader from '../../../Loader/pageloading/Pageloader';
import nodatafound from '../../../Images/Noimagefound/Noimgfound';
import ReactPaginate from "react-paginate";
import DeleteAlert from '../../popup/DeleteAlert';

const Viewdepartmenthd = () => {
    const user_details = getUserDetails();
    const [isadddepopen, setadddepopen] = useState(false);
    const[departmenthead ,setdepartmentHeadList]= useState([])
    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [deleteComponentId, setDeleteComponentId] = useState(null);

    const [editData, setEditData] = useState([]);
    const[iseditopen,seteditopen]=useState(false)
    const [loading, setIsloading] = useState(false);
    const [totaldedcount, setdedcount] = useState(0)
    const per_page = 10;
    const [currentpage, setCurrentPage] = useState(1);
    const [search, setSearchword] = useState("");

    const currentPageNo = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
      };
    
      const handleSearch = (event) => {
        setSearchword(event.target.value);
      }
    
    const editdepheadButton =(componentid)=>{
        const updatedList = departmenthead.filter((component)=>
            component.id ===componentid
        );
        console.log("updatwe loist",updatedList)
        if(updatedList){
            setEditData(updatedList[0]);
            seteditopen(true);
    
        }
    }
    const handleUpdateData =(data) =>{
        console.log("updated data",data)
    const updatedList = departmenthead.map((comp)=>
        comp.id === data.id?data:comp
    );
    setdepartmentHeadList(updatedList);
    }
    const closeeditlevae =() =>{
        seteditopen(false)
    }
    const openadddephd = () => {
        setadddepopen(true);
    }
    const closeadddephd = () => {
        setadddepopen(false);
    }
   
    const Fetchdepartmentapi = useCallback(async() =>{
        try{
            setIsloading(true);

            const response = await FetchDepartmentHead(`?register_id=${user_details.id}&page=${currentpage}&page_count=${per_page}&search=${search}`);
            if (response){
                setdedcount(response.data.page_context.total)
                setdepartmentHeadList(response.data.get_depart_head);

            }
        }catch(error){

        }finally{
            setIsloading(false)

        }
    },[currentpage, search,per_page,user_details.id]);
    useEffect(()=>{
        Fetchdepartmentapi();
    },[Fetchdepartmentapi])
    const confirmDelete = () => {
        if (deleteComponentId) {
            deletesalaryComponent(deleteComponentId);
            closeConfirmationModal();
        }
    };
    const deletesalaryComponent = async (componentId) => {
        try {
            const response = Deletedepheadapi(componentId);
            if (response) {
                const updatedList = departmenthead.filter((component) => component.id !== componentId);
                setdepartmentHeadList(updatedList);
            }
        }
        catch (error) {

        }
    }
    // const departmenthead = departmentHeadList.get_depart_head;
    const showConfirmationModalDelete = (delid) => {
        setDeleteComponentId(delid);
        setConfirmationModalOpen(true);

    }
    const closeConfirmationModal = () => {
        setDeleteComponentId(null);
        setConfirmationModalOpen(false);
    };
    const handleDataFromChild = (data) => {
        setdepartmentHeadList((prev) => [
            data,
            ...prev,
        ]);
    };
    return (
        <div className='index-container'>
            <Header />
            <div className='index-content-parent'>
                <div className='index-content'>
                    <Sidebar />
                    <div className='index-content-sub'>
                        <div className='index-main'>
                            <div className='pages-subheader'>
                                <div className='pages-subheader-sub-1'>
                                    <p>Department Head</p>
                                    <span className='header-search-add-parent'>
                                        <div className='pages-serach-view'>
                                            <CiSearch className='page-search-icon' />
                                            <input className='pages-serach' onChange={handleSearch}placeholder='Search Department Head' type='search' />
                                        </div>
                                        <button className='pages-addempbutton-leave' onClick={openadddephd}>Add Department Head</button>
                                    </span>
                                </div>
                                <div className='pages-line'></div>
                            </div>
                            <div className='pages-maincontent'>
                            {loading ? (
                  <div className="loader-container">
                    <img src={pageloader.page_loader} alt="Loading..." />
                  </div>
                )
                  : (
                                <table className='pages-table'>
                                    <thead>
                                        <tr>
                                            <th>Sl No</th>
                                            <th>Department</th>
                                            <th>Head Name</th>
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {departmenthead && departmenthead.length > 0 ? (
                                        departmenthead.map((departmenthead, index) => (
                                            <tr key={departmenthead.id}>
                                                <td>{index + 1}</td>
                                                <td>{departmenthead.depart_name}</td>
                                                <td>{departmenthead.depart_head}</td>
                                                <td>{departmenthead.user !== null ? (
                            <span style={{display:"flex",flexDirection:"row",gap:"20px",fontSize:"14px",alignItems:"center"}}>
                            <CiEdit  onClick={()=>editdepheadButton(departmenthead.id)} style={{color:"#2a7ac7",cursor:"pointer"}} />  
                            <CiTrash onClick={()=>showConfirmationModalDelete(departmenthead.id)} style={{color:"red",cursor:"pointer"}} /> 
                            </span> 
                        ):null}</td>
                                         
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                          <td colSpan={8} className="no-data-table">
              
                                            <img src={nodatafound.no_data_img} alt="No Data Found" className="no-data-found" />
              
              
              
                                          </td>
                                        </tr>
                                      )
                                        }
                                    </tbody>
                                </table>
                                          )}
                                                 <div style={{ float: "right" }} id="pagination_link"></div>
                <div style={{ padding: "20px" }}>
                  {totaldedcount > per_page ? (
                    <>
                      <ReactPaginate
                        pageCount={Math.ceil(totaldedcount / per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={currentPageNo}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </>
                  ) : (
                    <> </>
                  )}
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Adddepartmenthead
                show={isadddepopen}
                close={closeadddephd}
                sendDataToParent={handleDataFromChild}

            />
            <Editdepartmenthead
                        editData={editData}
                        updateData={handleUpdateData}
                        show={iseditopen}
                        close={closeeditlevae}
            />
                        <DeleteAlert
                isOpen={isConfirmationModalOpen}
                message="Really, would you like to remove this Component?"
                onCancel={closeConfirmationModal}
                onConfirm={confirmDelete}
            />
        </div>
    );
}

export default Viewdepartmenthd;
