import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY || '!@#$%%^&&seasense)(*&^&^%^'; 

export const storeUserToken = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
        localStorage.setItem('token', encryptedData);
    } catch (error) {
        console.error("Error encrypting token:", error);
    }
};

export const getUserToken = () => {
    try {
        const encryptedData = localStorage.getItem('token');
        if (!encryptedData) return null;
        
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData || null;
    } catch (error) {
        console.error("Error decrypting token:", error);
        return null;
    }
};

export const storeUserDetails = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('user', encryptedData);
    } catch (error) {
        console.error("Error encrypting user details:", error);
    }
};

export const getUserDetails = () => {
    try {
        const encryptedData = localStorage.getItem('user');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        console.error("Error decrypting or parsing user details:", error);
        return null;
    }
};


export const storeStaffDetails = (data) => {
    try {
        console.log("store stafff details s s" , data);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('Staff', encryptedData);
    } catch (error) {
        console.error("Error encrypting user details:", error);
    }
};

export const getStaffDetails = () => {
    try {
        const encryptedData = localStorage.getItem('Staff');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        console.error("Error decrypting or parsing user details:", error);
        return null;
    }
};

export const storeCompanyDetails = (data) => {
    try {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        localStorage.setItem('company', encryptedData);
    } catch (error) {
        console.error("Error encrypting company details:", error);
    }
};

export const getCompanyDetails = () => {
    try {
        const encryptedData = localStorage.getItem('company');
        if (!encryptedData) return null;

        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            return JSON.parse(decryptedData);
        }
        return null;
    } catch (error) {
        console.error("Error decrypting or parsing company details:", error);
        return null;
    }
};