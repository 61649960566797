import React, { useState,useEffect } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { toast } from 'react-toastify';
import './Profile.css';
import { getUserDetails, storeUserDetails } from '../../Services/Storage';
import { UpdateUserProfile } from '../../Config/api';
import manageimg from '../../Images/Manageaccount/Manageacc';

const Profile = () => {
  const user_details = getUserDetails();

  // const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address_line1: '',
    address_line2: '',
    password: '',
    city: '',
    state: '',
    pincode: '',
    role: "company"
  });
  useEffect(() => {
    setFormData({
      name: user_details.name || '',
      email: user_details.email || '',
      phone: user_details.phone || '',
      address_line1: user_details.address_line1 || '',
      address_line2: user_details.address_line2 || '',
      city: user_details.city || '',
      state: user_details.state || '',
      pincode: user_details.pincode || '',
      role: 'company'
    });
  }, [user_details.address_line1,
    user_details.name,
    user_details.email,
    user_details.phone,
    user_details.address_line2,
    user_details.city,
    user_details.state,
    user_details.pincode

  ]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };
  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword)
  // }
  const openeditprofile = () => {
    setEditing(!isEditing)
  }
  const handleclose =() =>{
    setEditing(false)
  }
  const handleSubmit = async () => {
    let newErrors = {};
    if (formData.name.trim() === "") {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(formData.name)) {
      newErrors.name =
        " Name must contain only alphabetic characters and spaces";

    } 
    const phonenumber = String(formData.phone).trim();
    if (phonenumber === "") {
      newErrors.phone = "Phone is required";
    } else if (!/^\d+$/.test(phonenumber)) {
      newErrors.phone = "Phone must contain only digits";
    } else if (phonenumber.length < 10 || phonenumber.length > 13) {
      newErrors.phone = "Phone must be between 10 to 13 digits";
    } 
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    } 
    if (formData.address_line1.trim() === '') {
      newErrors.address_line1 = 'Address Line1 is required';
    } if (formData.address_line2.trim() === '') {
      newErrors.address_line2 = 'Address Line2 is required';
    }
     if (formData.city.trim() === '') {
      newErrors.city = 'City is required';
    } 
    if (formData.state.trim() === '') {
      newErrors.state = 'State is required';
     }
     const pin_field = String(formData.pincode).trim()
    if (pin_field === '') {
      newErrors.pincode = 'Pincode is required';
    } else if (!/^\d+$/.test(pin_field)) {
      newErrors.pincode = 'Pincode must contain only digits';
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await UpdateUserProfile(user_details.id,formData);
        if (response) {
          toast.success('User Details Updated successful!', {
            position: "top-center",
            autoClose: 2000,
            closeButton: false,
            className: 'custom_toast'
          });
          console.log(response.data.updated_registration)
          storeUserDetails(response.data.updated_registration);
          setEditing(false)
          setTimeout(() => {
         
          }, 1000); // Navigate after 2 seconds (2000 ms)
        }
      }
      catch (error) {
        if (error.response) {
          const newErrors = {};
          newErrors.response_error = error.response.data.data;
          setErrors(newErrors);
        }
        else {
          toast.error('User Details Updated failed.', {
            position: "top-center",  // Use a string directly for the position
            autoClose: 2000,
            closeButton: false,
          });
          const newErrors = {
            response_error: [],
          }; for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              newErrors.response_error.push(`${error.response.data.errors[key]}`);
            }
          }
          setErrors(newErrors);
          setFormData((state) => ({
            ...state,
          }));
        }
      }
      finally {
        setLoading(false);
      }
    }
  }
  return (
    <div className='index-container'>
      <Header />
      <div className='index-content-parent'>
        <div className='index-content'>
          <Sidebar />
          <div className='index-content-sub'>
            <div className='index-main'>
              <div className='pages-subheader'>
                <div className='pages-subheader-sub-1'>
                  <p> Profile</p>
                  <span className='header-search-add-parent'>
                 </span>
                </div>
                <div className='pages-line'></div>
              </div>
              <div className='vieworgpro-maincontent'>
                {!isEditing ?
                  (<div className='registeration-main'>
                    <div className='manageacc-main'>
                      <div className='manageacc-main-sub'>
                        <div className='manageacc-editbutton'>
                          <button className='edit-manageacc' onClick={openeditprofile}>Edit</button>
                        </div>
                        <div className='first-input-manageacc'>
                          <div>
<img className ="manageacc-image-view"src={manageimg.manageaccount_imag} alt='img'/>
                          </div>
                          <div>
                          <h2>{user_details.name}</h2>
                          <p>{user_details.email}</p>  
                            </div>

                        </div>
                        <div class="pages-line"></div>
                        <div className='manageacc-container'>
                        <div className='manageacc-container1'>
                        <div className='manageacc-fieldview'>
                          <label className='label-view-manageacc'>Mobile No:</label>
                          <p>{user_details.phone}</p>
                        </div>
                        <div className='manageacc-fieldview'>
                          <label className='label-view-manageacc'>Address Line 1:</label>
                          <p>{user_details.address_line1}</p>
                        </div>
                        <div className='manageacc-fieldview'>
                          <label className='label-view-manageacc'>Address Line 2: </label>
                          <p>{user_details.address_line2}</p>
                        </div>
                        </div>
                        <div>
                        <div className='manageacc-fieldview'>
                          <label className='label-view-manageacc'>City & State:</label>
                          <p>{user_details.city} &{user_details.state}</p>
                        </div>
                        <div className='manageacc-fieldview'>
                          <label className='label-view-manageacc'>Pincode:</label>
                          <p>{user_details.pincode}</p>
                        </div>
                        </div>
                        </div>
      
                
                      

                      </div>
                    </div>
                  </div>)
                  :
                  (
                    <div className='registeration-main'>
                      <div>
                      <div className='registeration-input-row'>
                          <div className='registeration-input-group'>

                            <label htmlFor='first-input'> Name <span className='registeration-mandatory'>*</span></label>
                            <input id='first-input' type='text' onChange={handleInputChange} name='name' className='registeration-input-field' value={formData.name} />
                            {errors.name && (
                              <div className="registeration-error-view">{errors.name}</div>
                            )}
                          </div>
                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='third-input'>Phone <span className='registeration-mandatory'>*</span></label>
                            <input id='third-input' type='text' onChange={handleInputChange} name='phone' className='registeration-input-field' value={formData.phone} />
                            {errors.phone && (
                              <div className="registeration-error-view">{errors.phone}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='fifth-input'>Email <span className='registeration-mandatory'>*</span></label>
                            <input id='fifth-input' type='text' onChange={handleInputChange} name='email' value={formData.email} className='registeration-input-field' />
                            {errors.email && (
                              <div className="registeration-error-view">{errors.email}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                        </div>
                        <div className='registeration-input-row'>

                          <div className='registeration-input-group'>
                            <label htmlFor='second-input'>Address Line 1 <span className='registeration-mandatory'>*</span></label>
                            <input id='second-input' type='text' onChange={handleInputChange} value={formData.address_line1} name='address_line1' className='registeration-input-field input-cls-full' />
                            {errors.address_line1 && (
                              <div className="registeration-error-view">{errors.address_line1}</div>
                            )}
                          </div>
                          <div className='registeration-input-group'>
                            <label htmlFor='fourth-input'>Address Line 2 <span className='registeration-mandatory'>*</span></label>
                            <input id='fourth-input' type='text' onChange={handleInputChange} value={formData.address_line2} name='address_line2' className='registeration-input-field' />
                            {errors.address_line2 && (
                              <div className="registeration-error-view">{errors.address_line2}</div>
                            )}
                          </div>

                        </div>
                        <div className='registeration-input-row'>
                          <div className='registeration-input-group'>
                            <label htmlFor='sixth-input'>City <span className='registeration-mandatory'>*</span></label>
                            <input id='sixth-input' type='text' value={formData.city} onChange={handleInputChange} name='city' className='registeration-input-field' />
                            {errors.city && (
                              <div className="registeration-error-view">{errors.city}</div>
                            )}
                          </div>
                          <div className='registeration-input-group'>
                            <label htmlFor='eighth-input'>State <span className='registeration-mandatory'>*</span></label>
                            <input id='eighth-input' type='text' onChange={handleInputChange} name='state' className='registeration-input-field' value={formData.state} />
                            {errors.state && (
                              <div className="registeration-error-view">{errors.state}</div>
                            )}
                          </div>
                          <div className='registeration-input-group'>
                            <label htmlFor='tenth-input'>Pincode <span className='registeration-mandatory'>*</span></label>
                            <input id='tenth-input' type='text' onChange={handleInputChange} name='pincode' className='registeration-input-field' value={formData.pincode} />
                            {errors.pincode && (
                              <div className="registeration-error-view">{errors.pincode}</div>
                            )}
                          </div>
                        </div>
                        <div >
                          {errors.response_error && (
                            <div className="registeration-error-view">{errors.response_error}</div>
                          )}
                          <div className='manageacc-button-div'>
                           <button className='manageacc-button1'  onClick={handleclose}>Close</button>
                            <button className='manageacc-button' onClick={handleSubmit} disabled={loading}>
                              {loading ? <span className="spinner"></span> : 'Update'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile